/* Copyright Levelise Ltd 2019-2024 */
import React, { useState, useContext, useRef, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getTimezoneOffset } from 'date-fns-tz';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import config from '../../config';
import FleetContext from '../../contexts/FleetContext';
import FacilityContext from '../../contexts/FacilityContext';
import useOutsideClick from './outsideClick';
import profileIcon from '../../assets/icons/ProfileIcon.png';
import TimeFrames from '../TimeFrameOptions';
import UserService from '../../services/user-service';
import { FACILITY_TIME_FRAMES, FLEET_TIME_FRAMES, routes, PERMISSIONS, fleet } from '../../utils/constants';
import { hasPermission } from '../../utils/utils';
import './index.css';
import UserContext from '../../contexts/UserContext';
import FleetStatusContext from '../../contexts/FleetStatusContext';
import IdleService from '../../services/idle-service';
import TokenService from '../../services/token-service';
import FleetIcon from '../../assets/icons/FleetIcon';
import OrdersIcon from '../../assets/icons/clipboardIcon.svg';

const customStyles = {
	menu: (provided, state) => ({
		...provided,
		marginTop: 4,
		marginBottom: 0,
		padding: 0,
	}),
	container: (provided) => ({
		...provided,
		minHeight: 1,
		textAlign: 'left',
		border: 'none',
	}),
	control: (provided) => ({
		...provided,
		minHeight: 1,
		height: 22,
		borderRadius: 0,
		padding: 0,
		border: 'none',
		boxShadow: 'none',
		borderBottom: '1px solid rgba(187, 187, 187, 0.8)',
		cursor: 'pointer',
		'&:hover': { borderBottom: '1px solid rgb(187, 187, 187)' },
		'&:focus': { borderBottom: '1px solid rgb(187, 187, 187)' },
	}),
	input: (provided) => ({
		...provided,
		margin: 0,
		paddingBottom: 0,
		paddingTop: 0,
		minHeight: 20,
		height: 20,
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		height: 20,
		minHeight: 1,
		paddingTop: 0,
		paddingBottom: 0,
		paddingLeft: 0,
		paddingRight: 0,
		transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
	}),
	indicatorContainer: (provided) => ({
		...provided,
		height: 20,
		paddingTop: 0,
		paddingBottom: 0,
		paddingLeft: 2,
		paddingRight: 2,
	}),
	indicatorSeparator: (provided) => ({
		...provided,
		display: 'none',
	}),
	valueContainer: (provided) => ({
		...provided,
		minHeight: 1,
		padding: 0,
	}),
	singleValue: (provided) => ({
		...provided,
		minHeight: 0,
		paddingBottom: 0,
		color: 'rgb(110, 110, 110)',
	}),
};

const NavBar = ({ route, containerStyle }) => {
	const navigate = useNavigate();

	const pageTimezone = { 1: 'Facility', 2: 'Fleet', 3: 'Local', 4: 'UTC' };
	const routesWithoutTimezone = [routes.customer, routes.user, routes.fleet_customer, routes.orders];
	const customerSecondaryPages = ['/add-customer', '/customer'];

	const fleetContext = useContext(FleetContext);
	const facilityContext = useContext(FacilityContext);
	const userContext = useContext(UserContext);
	const fleetStatusContext = useContext(FleetStatusContext);

	const showTimeFrames = hasPermission(PERMISSIONS.CAN_ACCESS_AF);
	const [show, setShow] = useState(false);
	const [timeFramesVisible, setTimeFramesVisible] = useState('');
	const [timezones, setTimezones] = useState([]);
	const [timezone, setTimezone] = useState();

	const [username, setUsername] = useState('');

	const location = useLocation();

	const localTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const getUtcOffset = (tz) => {
		const offset = getTimezoneOffset(tz);
		const absOffset = Math.abs(offset);
		const hr = parseInt(absOffset / 3600000);
		const min = parseInt((absOffset % 3600000) / 60000);
		return `${offset < 0 ? '-' : '+'}${hr.toString().padStart(2, '0')}:${min.toString().padStart(2, '0')}`;
	};

	const defaultTimezones = () => {
		const fleetTimezone = fleetContext.timezone || 'Europe/London';
		return [
			{
				value: `Facility | ${fleetTimezone}`,
				label: `Facility | ${fleetTimezone} (UTC${getUtcOffset(fleetTimezone)})`,
			},
			{
				value: `Fleet | ${fleetTimezone}`,
				label: `Fleet | ${fleetTimezone} (UTC${getUtcOffset(fleetTimezone)})`,
			},
			{ value: `Local | ${localTz}`, label: `Local | ${localTz} (UTC${getUtcOffset(localTz)})` },
			{ value: 'UTC', label: `Coordinated Universal Time (UTC)` },
		];
	};

	const ref = useRef();

	useOutsideClick(ref, () => {
		if (show) setShow(false);
	});

	const handleOnClickLogo = () => {
		fleetContext.setCurrentDataType(fleet);
		fleetContext.setDisplay('');
		fleetContext.setSearchTerm('');
		fleetContext.setSearchDrus(null);
	};

	const toFleet = (e) => {
		handleOnClickLogo();

		if (UserService.hasUser() && UserService.getUser().isRoleUser) {
			e.preventDefault();
		}
	};

	const handleOnChangeTimezone = (e) => {
		setTimezone(e);
		if (route === routes.fleet) {
			fleetContext.setSelectedTimezone(e.value);
		}
		if (route === routes.facility) {
			facilityContext.setSelectedTimezone(e.value);
		}
	};

	const getAfTimezone = () => {
		for (let i = 0; i < fleetContext.aggFacilities.length; i++) {
			const af = fleetContext.aggFacilities[i];
			if (af.name === fleetContext.currentDataType) return af.facilitiesTimezone;
			if (af.hasOwnProperty('constituents'))
				for (let j = 0; j < af.constituents.length; j++)
					if (af.constituents[j].name === fleetContext.currentDataType)
						return af.constituents[j].facilitiesTimezone;
		}
		return fleetContext.timezone;
	};

	const onHandleLogout = () => {
		userContext.clearUser();
		fleetContext.clearState();
		facilityContext.clearState();
		fleetStatusContext.clearState();
		IdleService.unRegisterIdleResets();
		UserService.removePreferences();
		UserService.removeUser();
		UserService.removeUserDetail();
		TokenService.clearAuthToken();
		TokenService.clearCallbackBeforeExpiry();
		navigate('/auth'); // /login
	};

	const onUsernameClick = () => {
		navigate('/user');
	};

	const onFleetCustomersClick = () => {
		fleetContext.setFleetCustomersList([]);
		navigate('/fleet-customers');
	};

	const onOrdersClick = () => {
		navigate('/orders');
	};

	const getUsername = () => { 
		const userDetail = UserService.getUserDetail();
		if (!userDetail) {
			return '';
		}

		let name = `${userDetail?.forename?.slice(0, 1) || ''} ${userDetail?.surname || ''}`;
		if (name.length > 16) {
			name = name.slice(0, 14) + '...';
		}

		setUsername(name);
	};

	useEffect(() => {
		if (UserService.hasUser()) {
			getUsername();
		} else {
			setUsername('');
		}

		if (route !== routes.fleet && route !== routes.facility) {
			setTimeFramesVisible('hideTimeFrames');
			return;
		}

		setTimeFramesVisible('showFramesShow');
	}, [route]);

	useEffect(() => {
		const preference = UserService.getPreferences();
		const tzs = defaultTimezones();
		let currentTimezoneType = 'UTC';
		if (!!preference) {
			if (route === routes.facility) {
				const facilityTimezone = facilityContext.facility.timezone || 'Europe/London';
				tzs[0] = {
					value: `Facility | ${facilityTimezone}`,
					label: `Facility | ${facilityTimezone} (UTC${getUtcOffset(facilityTimezone)})`,
				};
				if (!!facilityContext.selectedTimezone.length) {
					currentTimezoneType = facilityContext.selectedTimezone.split(' | ')[0].trim();
				} else {
					currentTimezoneType = pageTimezone[preference[config.facilityPageTimezone]];
				}
			} else {
				if (fleetContext.haveSetTimezonePreference && !!fleetContext.selectedTimezone.length) {
					currentTimezoneType = fleetContext.selectedTimezone.split(' | ')[0].trim();
				} else {
					currentTimezoneType = pageTimezone[preference[config.fleetPageTimezone]];
					fleetContext.setHaveSetTimezonePreference(true);
				}
			}
		}

		setTimezones(tzs);
		switch (currentTimezoneType) {
			case 'Facility':
				handleOnChangeTimezone(tzs[0]);
				break;
			case 'Fleet':
				handleOnChangeTimezone(tzs[1]);
				break;
			case 'Local':
				handleOnChangeTimezone(tzs[2]);
				break;
			case 'UTC':
				handleOnChangeTimezone(tzs[3]);
				break;
			default:
				handleOnChangeTimezone('UTC');
		}
	}, [fleetContext.timezone, facilityContext.facility]);

	useEffect(() => {
		if (route === routes.fleet && fleetContext.currentDataType !== '') {
			const fleetTimezone = fleetContext.timezone || 'Europe/London';
			const tzs = defaultTimezones();
			if (fleetContext.currentDataType !== fleet) {
				const afTz = getAfTimezone();
				tzs[0] = { value: `Facility | ${afTz}`, label: `Facility | ${afTz} (UTC${getUtcOffset(afTz)})` };
			} else {
				tzs[0] = {
					value: `Facility | ${fleetTimezone}`,
					label: `Facility | ${fleetTimezone} (UTC${getUtcOffset(fleetTimezone)})`,
				};
			}

			if (!!timezone && timezone.value.includes('Facility') && timezone.value !== tzs[0].value) {
				handleOnChangeTimezone(tzs[0]);
			}
			setTimezones(tzs);
		}
	}, [fleetContext.currentDataType]);

	return (
		<nav className={`nav-bar ${timeFramesVisible}`} style={containerStyle}>
			<div className="left left-flex">
				<div className="back-logo-customer-container">
					<div>
						<Link to="/fleet" onClick={(e) => toFleet(e)}>
							{/* <img
								src={logo}
								className="sm-logo"
								alt="Levelise-logo"
								onClick={() => handleOnClickLogo()}
							/> */}
							<div className="group-icon-container">
							<FleetIcon />
							</div>
						</Link>

						{location.pathname === '/fleet' ? (
							<div className="tab-underline active" />
						) : location.pathname.startsWith('/facility/') ||
						  location.pathname === '/configure-facility' ? (
							<div className="tab-underline inactive" />
						) : null}
					</div>
				</div>
				
				{hasPermission(PERMISSIONS.CAN_ACCESS_CUSTOMER) ? (
					<div>
						<div
							data-cy="fleet-customers-button"
							className="group-icon-container"
							onClick={onFleetCustomersClick}
						>
							<img src={profileIcon} className="group-icon group-icon-1" alt="se-logo" />
							<img src={profileIcon} className="group-icon group-icon-2" alt="se-logo" />
							<img src={profileIcon} className="group-icon group-icon-3" alt="se-logo" />
						</div>
						{location.pathname === '/fleet-customers' ? (
							<div className="tab-underline active" />
						) : customerSecondaryPages.includes(location.pathname) ? (
							<div className="tab-underline inactive" />
						) : null}
					</div>
				) : null}
				{hasPermission(PERMISSIONS.CAN_CONTROL_DRU) ? (
					<div>
						<div
							data-cy="fleet-customers-button"
							className="clipboard-icon-container"
							onClick={onOrdersClick}
						>
							<img src={OrdersIcon} width={20} alt='orders' />
						</div>
						{location.pathname === '/orders' ? (
							<div className="tab-underline active" />
						) : null}
					</div>
				) : null}
				<div className="user-name-container">
					<button
						className={`username ${username.includes('...') && route === routes.user ? 'long-name' : ''}`}
						ref={ref}
						onClick={onUsernameClick}
					>
						<img src={profileIcon} className="profileIcon" alt="se-logo" />
						&nbsp;
						{username}
					</button>
					{location.pathname === '/user' ? <div className="tab-underline active" /> : null}
				</div>
			</div>

			{route === routes.fleet && showTimeFrames && <TimeFrames timeFrames={FLEET_TIME_FRAMES} route={route} />}
			{route === routes.facility && <TimeFrames timeFrames={FACILITY_TIME_FRAMES} route={route} />}

			{!routesWithoutTimezone.includes(route) || route === routes.user ? (
				<div className={`right ${route !== routes.user ? 'with-boarder' : ''}`}>
					{!!timezones.length && !routesWithoutTimezone.includes(route) && (
						<div className="select-timezone-wrapper">
							<Select
								styles={customStyles}
								isSearchable={true}
								value={timezone}
								onChange={(value) => handleOnChangeTimezone(value)}
								options={timezones}
							/>
						</div>
					)}
					{route === routes.user ? (
						<button
							className={`username ${
								username.includes('...') && route === routes.user ? 'long-name' : ''
							}`}
							ref={ref}
							onClick={onHandleLogout}
						>
							<FontAwesomeIcon icon={faSignOutAlt} />
							&nbsp; Logout
						</button>
					) : null}
				</div>
			) : null}
		</nav>
	);
};

export default NavBar;
