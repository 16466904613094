/* Copyright Levelise Ltd 2019-2024 */
import React, { useState, useEffect, useRef, useContext } from 'react';
import { getUnixTime } from 'date-fns';
import facilityContext from '../../contexts/FacilityContext';
import { colors } from '../../utils/chart'
import { formatTimestamp } from '../../utils/utils'
import './index.css';

const EnergyBalance = ({ record, recordByMinute }) => {
    const ref = useRef(null);
    const context = useContext(facilityContext);
    const [dimensions, setDimensions] = useState({});
    const [isDisconnected, setIsDisconnected] = useState(true);
    const [isRecovering, setIsRecovering] = useState(true);
    const [timestampMsec, setTimestampMsec] = useState(0);
    const [timestampSec, setTimestampSec] = useState(0);
    const [hasBattery, setHasBattery] = useState(context.facility.hasOwnProperty('batterySystem'));
    const [hasHotWater, setHasHotWater] = useState(context.facility.hasOwnProperty('hotWaterTank'));
    const [drawOff, setDrawOff] = useState({ flow: '', temp: '' });
    const [temps, setTemps] = useState({ temp1_8C: '', temp3_8C: '', temp5_8C: '', temp7_8C: '' })
    const [energy, setEnergy] = useState({
        recordTimestamp: '',
        batteryChargingW: '',
        batteryDischargingW: '',
        loadW: '',
        gridIncomingW: '',
        gridOutgoingW: '',
        pvW: '',
        dispatch: '',
        soc: '',
        hotWater: ''
    })


    const colorsEnergy = {
        pv: isDisconnected || isRecovering ? colors.trolley_grey : colors.usc_gold,
        load: isDisconnected || isRecovering ? colors.trolley_grey : colors.lavender,
        grid: isDisconnected || isRecovering ? colors.trolley_grey : colors.trolley_grey,
        battery: isDisconnected || isRecovering ? colors.trolley_grey : colors.maximum_blue,
        soc: isDisconnected || isRecovering ? colors.trolley_grey : colors.green,
        dispatch: isDisconnected || isRecovering ? colors.trolley_grey : colors.red,
        hotWater: isDisconnected || isRecovering ? colors.trolley_grey : colors.light_blue,
        drawOff: isDisconnected || isRecovering ? colors.trolley_grey : colors.indianred.border,
        dcPvW: isDisconnected || isRecovering ? colors.trolley_grey : colors.orangeNewObj.border,
        inverter: isDisconnected || isRecovering ? colors.trolley_grey : colors.black.border,
    }

    const updateState = () => {
        if (record && Object.keys(record).length) {
            const { drDispatchedImportW, drDispatchedExportW } = record;
            const drDispatchedW = drDispatchedImportW < drDispatchedExportW ? -drDispatchedExportW : drDispatchedImportW;
            if (handleBySecondRecordState(record.timestampMsec) && timestampMsec < record.timestampMsec) {
                const obj = {
                    recordTimestamp: record.timestampMsec,
                    batteryChargingW: record.batteryChargingW,
                    batteryDischargingW: record.batteryDischargingW,
                    loadW: record.loadW,
                    gridIncomingW: record.gridIncomingW,
                    gridOutgoingW: record.gridOutgoingW,
                    pvW: record.pvW,
                    dispatch: !!drDispatchedW || drDispatchedW === 0 ? Math.abs(drDispatchedW) : '',
                    soc: !!record.soc || record.soc === 0 ? Math.round(record.soc) : '',
                    hotWater: hasHotWater ? record.hwHeatingW : ''
                };

                if (Object.hasOwn(record, 'dcPvW')) {
                    obj['dcPvW'] = record.dcPvW;
                }
                if (Object.hasOwn(record, 'inverterChargingW')) {
                    obj['inverterChargingW'] = record.inverterChargingW;
                }
                if (Object.hasOwn(record, 'inverterDischargingW')) {
                    obj['inverterDischargingW'] = record.inverterDischargingW;
                }

                setEnergy(obj);
            }
            setTimestampMsec(record.timestampMsec);
        }
    }

    const updateTempState = () => {
        if (Object.keys(recordByMinute).length) {
            const timestamp = recordByMinute.timestampSec;
            if (timestampSec < timestamp && handleByMinuteRecordState(timestamp)) {
                setDrawOff({
                    flow: !!recordByMinute.flowL ? recordByMinute.flowL.toFixed(2) : 0,
                    temp: !!recordByMinute.outletTempC ? recordByMinute.outletTempC.toFixed(1) : 0
                });
                setTemps({
                    temp1_8C: !!recordByMinute.temp1_8C ? recordByMinute.temp1_8C.toFixed(1) : '',
                    temp3_8C: !!recordByMinute.temp3_8C ? recordByMinute.temp3_8C.toFixed(1) : '',
                    temp5_8C: !!recordByMinute.temp5_8C ? recordByMinute.temp5_8C.toFixed(1) : '',
                    temp7_8C: !!recordByMinute.temp7_8C ? recordByMinute.temp7_8C.toFixed(1) : ''
                });
            }
            setTimestampSec(timestamp);
        }
    }

    const handleByMinuteRecordState = tSec => {
        const ts = getUnixTime(new Date());
        if (ts - tSec > 180) {
            return timestampSec < tSec;
        }
        return true;
    }

    const handleBySecondRecordState = tMsec => {
        const ts = getUnixTime(new Date());
        if ((ts * 1000 - tMsec) > 180000) {
            const recovering = timestampMsec < tMsec;
            setIsRecovering(recovering);
            setIsDisconnected(!recovering);
            return recovering
        }
        setIsRecovering(false);
        setIsDisconnected(false);
        return true
    }

    const getAnimationDuration = value => {
        if (isDisconnected || value === '' || Math.abs(value) === 0)
            return '0s';
        return (7 / Math.log(Math.abs(value))) + 's';
    };

    const getAnimationDurationDrawOff = value => {
        if (isDisconnected || value === '' || Math.abs(value) === 0)
            return '0s';
        return (8 / Math.log(Math.abs(value * 500))) + 's';
    };

    const flowPaths = () => {
        const { x, y, width, height } = dimensions;
        const battery = energy.batteryChargingW || 0 - energy.batteryDischargingW || 0;
        const grid = energy.gridIncomingW || 0 - energy.gridOutgoingW || 0;
        const batteryFlowDirection = battery < 0 ? " reverse" : "";
        const gridFlowDirection = grid < 0 ? " reverse" : "";
        const dispatchDirection = energy.dispatch && energy.dispatch > 0 ? " reverse" : "";
        return (
            <>
                <path //load flow path(1)
                    className="arc animation"
                    stroke={colorsEnergy.load}
                    fill="none"
                    style={{ "animationDuration": getAnimationDuration(energy.loadW) }}
                    d={`M ${width * 0.365},${height * 0.5} L ${width * 0.365},${height * 0.634}`} />
                {hasHotWater &&
                    <>
                        <path //hot water flow path(1)
                            className={"arc animation"}
                            stroke={colorsEnergy.hotWater}
                            fill="none"
                            style={{ "animationDuration": getAnimationDuration(energy.hotWater) }}
                            d={`M ${width * 0.16},${height * 0.758} L ${width * 0.368},${height * 0.758}`} />
                        <path //hot water flow path(2)
                            className={"arc animation"}
                            stroke={colorsEnergy.hotWater}
                            fill="none"
                            style={{ "animationDuration": getAnimationDuration(energy.hotWater) }}
                            d={`M ${width * 0.365},${height * 0.76} L ${width * 0.365},${height * 0.717}`} />
                        <path //hot water darw off path(1)
                            className={"arc animation flow"}
                            stroke={colorsEnergy.drawOff}
                            fill="none"
                            style={{ "animationDuration": getAnimationDurationDrawOff(drawOff.flow) }}
                            d={`M ${width * 0.293},${height * 0.6257} L ${width * 0.144},${height * 0.6257}`} />
                    </>
                }
                <path //pv flow path(1)
                    className="arc animation"
                    stroke={colorsEnergy.pv}
                    fill="none"
                    style={{ "animationDuration": getAnimationDuration(energy.pvW) }}
                    d={`M ${width * 0.468},${height * 0.31} L ${width * 0.52},${height * 0.31}`} />
                <path //pv flow path(2)
                    className="arc animation"
                    stroke={colorsEnergy.pv}
                    fill="none"
                    style={{ "animationDuration": getAnimationDuration(energy.pvW) }}
                    d={`M ${width * 0.47},${height * 0.654} L ${width * 0.47},${height * 0.305}`} />
                <path //pv flow path(3)
                    className="arc animation"
                    stroke={colorsEnergy.pv}
                    fill="none"
                    style={{ "animationDuration": getAnimationDuration(energy.pvW) }}
                    d={`M ${width * 0.415},${height * 0.65} L ${width * 0.473},${height * 0.65}`} />
                {hasBattery &&
                    <path //battery flow path(1)
                        className={"arc animation" + batteryFlowDirection}
                        stroke={colorsEnergy.battery}
                        fill="none"
                        style={{ "animationDuration": getAnimationDuration(battery) }}
                        d={`M ${width * 0.5},${height * 0.7} L ${width * 0.415},${height * 0.7}`} />
                }
                <path //grid flow path(1)
                    className={"arc animation" + gridFlowDirection}
                    stroke={colorsEnergy.grid}
                    fill="none"
                    style={{ "animationDuration": getAnimationDuration(grid) }}
                    d={`M ${width * 0.398},${height * 0.91} L ${width * 0.84},${height * 0.91}`} />
                <path //grid flow path(2)
                    className={"arc animation" + gridFlowDirection}
                    stroke={colorsEnergy.grid}
                    fill="none"
                    style={{ "animationDuration": getAnimationDuration(grid) }}
                    d={`M ${width * 0.40},${height * 0.718} L ${width * 0.40},${height * 0.914}`} />
                <path //disatch flow path(1)
                    className={"arc animation" + dispatchDirection}
                    stroke={colorsEnergy.dispatch}
                    fill="none"
                    style={{ "animationDuration": getAnimationDuration(energy.dispatch) }}
                    d={`M ${width * 0.915},${height * 0.422} L ${width * 0.915},${height * 0.72}`} />
            </>
        )
    }

    const batterySoc = () => {
        const { soc } = energy;
        const { x, y, width, height } = dimensions;
        const h = height * 0.04;
        const w = width * 0.06;
        if (hasBattery) {
            return (
                <>
                    <g transform={`translate(${width * 0.544}, ${height * 0.63})`}>
                        <rect x="0" y="0"
                            height={height * 0.14}
                            width={width * 0.0675}
                            fill={isDisconnected || isRecovering ? colors.trolley_grey : "#7FE4AF"}
                            stoke={colorsEnergy.battery}
                        >
                        </rect>
                        <rect x="0" y={height * 0.14 * (1 - (soc || 0) / 100)}
                            height={height * 0.14 * ((soc || 0) / 100)}
                            width={width * 0.0674}
                            fill={isDisconnected || isRecovering ? colors.darkgray.border : "#5FB989"}
                            stoke={colorsEnergy.battery}
                        >
                        </rect>
                    </g>
                    <g transform={`translate(${width * 0.612}, ${height * 0.695})`}>
                        <rect x={-w / 2} y={-h / 2}
                            width={w} height={h}
                            style={{ fill: "white", strokeWidth: 1, stroke: colorsEnergy.soc }}>
                        </rect>
                        <text textAnchor="middle">
                            <tspan className="flow-value" fill={colorsEnergy.soc} x="0" y={h / 4}>
                                {soc}%
                            </tspan>
                        </text>
                    </g>
                </>
            )
        }
    }

    const flowText = () => {
        const { x, y, width, height } = dimensions;
        const h = height * 0.04, w = width * 0.082;
        return (
            <>
                <g transform={`translate(${width * 0.47}, ${height * 0.457})`}>
                {
                        energy && Object.hasOwn(energy, 'dcPvW') ? (
                            <>
                                <rect x={-w / 2} y={-h / 2}
                                    width={w} height={h}
                                    style={{ fill: "white", strokeWidth: 1, stroke: colorsEnergy.dcPvW }}>
                                </rect>
                                <text textAnchor="middle">
                                    <tspan className="flow-value" fill={colorsEnergy.dcPvW} x="0" y={h / 4}>
                                        {energy.dcPvW}W
                                    </tspan>
                                </text>
                            </>
                        ) : null
                    }
                    
                    <rect x={-w / 2} y={(h - (h/2)) +0.5}
                        width={w} height={h}
                        style={{ fill: "white", strokeWidth: 1, stroke: colorsEnergy.pv }}>
                    </rect>
                    <text textAnchor="middle">
                        <tspan className="flow-value" fill={colorsEnergy.pv} x="0" y={h + 3}>
                            {energy.pvW}W
                        </tspan>
                    </text>
                </g>
                <g transform={`translate(${width * 0.365}, ${height * 0.57})`}>
                    <rect x={-w / 2} y={-h / 2}
                        width={w} height={h}
                        style={{ fill: "white", strokeWidth: 1, stroke: colorsEnergy.load }}>
                    </rect>
                    <text textAnchor="middle">
                        <tspan className="flow-value" fill={colorsEnergy.load} x="0" y={h / 4}>
                            {energy.loadW}W
                        </tspan>
                    </text>
                </g>
                {hasBattery &&
                    <g transform={`translate(${width * 0.451}, ${height * 0.735})`}>
                        <rect x={-w / 2} y={-h / 2}
                            width={w} height={h}
                            style={{ fill: "white", strokeWidth: 1, stroke: colorsEnergy.battery }}>
                        </rect>
                        <text textAnchor="middle">
                            <tspan className="flow-value" fill={colorsEnergy.battery} x="0" y={h / 4}>
                                {energy.batteryChargingW || energy.batteryDischargingW}W
                            </tspan>
                        </text>
                        {
                            energy && (Object.hasOwn(energy, 'inverterChargingW') || Object.hasOwn(energy, 'inverterDischargingW')) ? (
                                <>
                                    <rect x={-w / 2} y={(h - (h/2)) + 0.5}
                                        width={energy?.inverterDischargingW > 0 ? w + 2 : w} height={h}
                                        style={{ fill: "white", strokeWidth: 1, stroke: colorsEnergy.inverter }}>
                                    </rect>
                                    <text textAnchor="middle">
                                        <tspan className="flow-value" fill={colorsEnergy.inverter} x="0" y={h + 3}>
                                            {energy?.inverterDischargingW > 0 ? '-' : ''}{energy.inverterChargingW || energy.inverterDischargingW}W
                                        </tspan>
                                    </text>
                                </>
                            ) : null
                        }
                    </g>
                }
                {hasHotWater &&
                    <>
                        <g transform={`translate(${width * 0.23}, ${height * 0.758})`}>
                            <rect x={-w / 2} y={-h / 2}
                                width={w} height={h}
                                style={{ fill: "white", strokeWidth: 1, stroke: colorsEnergy.hotWater }}>
                            </rect>
                            <text textAnchor="middle">
                                <tspan className="flow-value" fill={colorsEnergy.hotWater} x="0" y={h / 4}>
                                    {energy.hotWater}W
                                </tspan>
                            </text>
                        </g>
                        <g transform={`translate(${width * 0.23}, ${height * 0.565})`}>
                            <rect x={-w / 2} y={-h / 2}
                                width={w} height={h * 1.73}
                                style={{ fill: "white", strokeWidth: 1, stroke: colorsEnergy.drawOff }}>
                            </rect>
                            <text textAnchor="middle">
                                <tspan className="flow-value" fill={colorsEnergy.drawOff} x="0" y={h / 4}>
                                    {drawOff.flow}
                                </tspan>
                            </text>
                            <text textAnchor="middle">
                                <tspan className="flow-value" fill={colorsEnergy.drawOff} x="0" y={h / 1}>
                                    L/min
                                </tspan>
                            </text>
                        </g>
                        <g transform={`translate(${width * 0.23}, ${height * 0.659})`}>
                            <rect x={-w / 2} y={-h / 2}
                                width={w} height={h}
                                style={{ fill: "white", strokeWidth: 1, stroke: colorsEnergy.drawOff }}>
                            </rect>
                            <text textAnchor="middle">
                                <tspan className="flow-value" fill={colorsEnergy.drawOff} x="0" y={h / 4}>
                                    {drawOff.temp}°C
                                </tspan>
                            </text>
                        </g>
                    </>
                }
                <g transform={`translate(${width * 0.47}, ${height * 0.91})`}>
                    <rect x={-w / 2} y={-h / 2}
                        width={w} height={h}
                        style={{ fill: "white", strokeWidth: 1, stroke: colorsEnergy.grid }}>
                    </rect>
                    <text textAnchor="middle">
                        <tspan className="flow-value" fill={colorsEnergy.grid} x="0" y={h / 4}>
                            {energy.gridIncomingW || energy.gridOutgoingW}W
                        </tspan>
                    </text>
                </g>
                <g transform={`translate(${width * 0.915}, ${height * 0.56})`}>
                    <rect x={-w / 2} y={-h / 2}
                        width={w} height={h}
                        style={{ fill: "white", strokeWidth: 1, stroke: colorsEnergy.dispatch }}>
                    </rect>
                    <text textAnchor="middle">
                        <tspan className="flow-value" fill={colorsEnergy.dispatch} x="0" y={h / 4}>
                            {energy.dispatch}W
                        </tspan>
                    </text>
                </g>
            </>
        )
    }

    const tankTempsAndFlow = () => {
        const { x, y, width, height } = dimensions;
        const h = height * 0.034;
        const { indianred, coral, gold, mediumseagreen } = colors;
        const { temp1_8C, temp3_8C, temp5_8C, temp7_8C } = temps;
        return (
            <>
                <g transform={`translate(${width * 0.085}, ${height * 0.633})`}>
                    <text textAnchor="middle">
                        <tspan className="temp-value" fill={indianred.border} x="0" y={h / 4}>
                            {temp7_8C}&#x2103;
                        </tspan>
                    </text>
                </g>
                <g transform={`translate(${width * 0.085}, ${height * 0.672})`}>
                    <text textAnchor="middle">
                        <tspan className="temp-value" fill={coral.border} x="0" y={h / 4}>
                            {temp5_8C}&#x2103;
                        </tspan>
                    </text>
                </g>
                <g transform={`translate(${width * 0.085}, ${height * 0.711})`}>
                    <text textAnchor="middle">
                        <tspan className="temp-value" fill={gold.border} x="0" y={h / 4}>
                            {temp3_8C}&#x2103;
                        </tspan>
                    </text>
                </g>
                <g transform={`translate(${width * 0.085}, ${height * 0.746})`}>
                    <text textAnchor="middle">
                        <tspan className="temp-value" fill={mediumseagreen.border} x="0" y={h / 4}>
                            {temp1_8C}&#x2103;
                        </tspan>
                    </text>
                </g>
            </>
        )
    }

    const time = () => {
        const { x, y, width, height } = dimensions;
        const list = context.selectedTimezone.split(' | ');
        const timezone = list[list.length - 1].trim();
        return (
            <g transform={`translate(${width * 0.02}, ${height * 0.12})`}>
                <text textAnchor="left">
                    <tspan className="report-time" fill="black" x="0" y="0" >
                        {!!timestampMsec && !!timezone &&
                            formatTimestamp(timestampMsec / 1000, timezone, 'dd/MM/yyy')}
                    </tspan>
                    <tspan className="report-time" fill="black" x="5" y="11" >
                        {!!timestampMsec && !!timezone &&
                            formatTimestamp(timestampMsec / 1000, timezone, 'HH:mm:ss')}
                    </tspan>
                </text>
            </g>
        )
    }

    const houseImgId = () => {
        if (hasHotWater) {
            if (!hasBattery) {
                return !isDisconnected && !isRecovering ? "house-hw-img" : "house-hw-disconnected-img";
            }
            return !isDisconnected && !isRecovering ? "house-hw-battery-img" : "house-hw-battery-disconnected-img";
        }

        return !isDisconnected && !isRecovering ? "house-img" : "house-disconnected-img";
    }

    useEffect(() => updateState(), [record])
    useEffect(() => updateTempState(), [recordByMinute])
    useEffect(() => setHasBattery(context.facility.hasOwnProperty('batterySystem')), [context.facility])
    useEffect(() => setHasHotWater(context.facility.hasOwnProperty('hotWaterTank')), [context.facility])

    useEffect(() => {
        updateState();
        updateTempState();
    }, [dimensions.x, dimensions.y, dimensions.width, dimensions.height])

    useEffect(() => {
        const handleResize = () => setDimensions(ref.current.getBoundingClientRect());
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    return (
        <svg
            ref={ref}
            id={houseImgId()}
            className="energy-balance"
            xmlns="http://www.w3.org/2000/svg" >
            {!!dimensions.x && timestampMsec !== 0 && time()}
            {!!dimensions.x && batterySoc()}
            {!!dimensions.x && flowPaths()}
            {!!dimensions.x && flowText()}
            {!!dimensions.x && hasHotWater && tankTempsAndFlow()}
        </svg>
    )
}

export default EnergyBalance;


