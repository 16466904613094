/* Copyright Levelise Ltd 2020-2024 */
import config from '../config';

const UserService = {
    saveUser(user) {
        sessionStorage.setItem(config.User, JSON.stringify(user));
    },

    getUser() {
        const user = sessionStorage.getItem(config.User);
        return !!user ? JSON.parse(user) : null;
    },

    removeUser() {
        sessionStorage.removeItem(config.User);
    },
    
    saveUserDetail(detail) {
        sessionStorage.setItem(config.userDetail, JSON.stringify(detail));
    },

    getUserDetail() {
        const detail = sessionStorage.getItem(config.userDetail);
        return detail ?  JSON.parse(detail) : null;
    },

    removeUserDetail() {
        sessionStorage.removeItem(config.userDetail);
    },

    hasUser() {
        return !!sessionStorage.getItem(config.User);
    },

    getUsername() {
        return UserService.hasUser() ? UserService.getUser().username  : '';
    },

    isAdmin() {
        if (UserService.hasUser()) {
            return UserService.getUser()[config.Role].includes(config.Role_Admin);
        }
        return false;
    },

    hasPreferences() {
        return !!sessionStorage.getItem(config.preferences);
    },

    setPreferences(preferences) {
        sessionStorage.setItem(config.preferences, JSON.stringify(preferences));
    },

    getPreferences() {
        const preference = sessionStorage.getItem(config.preferences);
        return !!preference ? JSON.parse(preference) : null;
    },

    removePreferences() {
        sessionStorage.removeItem(config.preferences);
    }
}

export default UserService