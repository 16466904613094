/* Copyright Levelise Ltd 2019-2024 */
import ja from 'date-fns/locale/ja/index.js';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useAlert } from 'react-alert';
import FleetContext from '../../contexts/FleetContext';
import FleetService from '../../services/fleet-service';
import { STATUS, SEVERITY, SEVERITY_COLOR } from '../../utils/constants';
import './index.css';
import DrusListHeader from '../DrusListHeader';

const DruList = ({ directTo }) => {
	const alert = useAlert();
	const context = useContext(FleetContext);
	const [searchTerm, setSearchTerm] = useState('');
	const [druList, setDruList] = useState([]);

	const ref = useRef(null);

	const fetchDruSpec = (druId) => {
		FleetService.getFacilityByDruId(druId)
			.then((res) => {
				directTo(res.name);
			})
			.catch((err) => {
				if (err.message === `No facility was found for DRU ID ${druId}`) {
					alert.show(err.message);
				} else {
					context.setError(err);
				}
			});
	};

	const includes = (druStatus) => {
		if (!searchTerm.length) return true;
		// else...
		const match = searchTerm.toLowerCase();
		const [dru, statuses, faults] = druStatus;
		if (String(dru).includes(match)) return true;
		if (statuses.join(', ').toLowerCase().includes(match)) return true;
		if (
			faults
				.map((fault) => fault.description)
				.join(', ')
				.toLowerCase()
				.includes(match)
		)
			return true;
		return false;
	};

	const getList = () => {
		return druList.sort((a, b) => parseInt(a[0]) - parseInt(b[0])).filter((dru) => includes(dru));
	};

	const listContent = () => {
		return getList().map((druStatus, i) => {
			const [dru, statuses, faults] = druStatus;
			faults.sort((a, b) => b.severity - a.severity);
			return (
				<li key={i} className="dru-item" onClick={() => fetchDruSpec(parseInt(dru))}>
					<span style={{ fontFamily: 'Inter-Bold' }}>
						{`${dru}${!!statuses && !!statuses.length ? ' - ' + statuses.join(', ') : ''}`}
					</span>
					<span>
						{faults.map((fault, j) => (
							<span
								key={j}
								style={{
									display: 'list-item',
									paddingTop: 3,
									color: SEVERITY_COLOR[fault.level].backgroundSeverity,
								}}
							>
								{fault.description}
							</span>
						))}
					</span>
				</li>
			);
		});
	};

	const handleOnChange = (e) => {
		e.preventDefault();
		setSearchTerm(e.target.value);
	};

	const drusObj = (status) => {
		const faultCodes = context.faultCodes;
		const faultCodeDrus = status['faultCode'];
		const druObj = {};
		const drus = context.currentDrus;
		for (const [key, field] of Object.entries(STATUS)) {
			switch (key) {
				case 'Unsafe':
				case 'Unoptimisable':
				case 'Diminished':
				case 'Faulty':
				case 'Glitchy':
				case 'Noted':
					const severestFaultDrus = status['severestFault'][field];
					if (!!severestFaultDrus.length) {
						Object.keys(faultCodeDrus).forEach((faultCode) => {
							if (!!faultCodes.hasOwnProperty(faultCode)) {
								faultCodeDrus[faultCode].forEach((dru) => {
									if (
										(drus === null || drus.indexOf(Number(dru))) >= 0 &&
										severestFaultDrus.indexOf(Number(dru)) >= 0
									) {
										if (!!druObj[dru]) {
											druObj[dru].faults.push(faultCodes[faultCode]);
										} else {
											druObj[dru] = { statuses: [key], faults: [faultCodes[faultCode]] };
										}
									}
								});
							}
						});
					}
					break;
				case 'No Facility':
				case 'Retired':
				case 'Not registered':
					status[field].forEach((dru) => {
						if (drus === null || drus.indexOf(Number(dru)) >= 0) {
							if (!!druObj[dru]) {
								druObj[dru].faults.push({ severity: 0, level: 'Inactive', description: key });
							} else {
								druObj[dru] = {
									statuses: ['Inactive'],
									faults: [{ severity: 0, level: 'Inactive', description: key }],
								};
							}
						}
					});
					break;
				default:
					status[field].forEach((dru) => {
						if (drus === null || drus.indexOf(Number(dru)) >= 0) {
							if (!!druObj[dru]) {
								druObj[dru].statuses.push(key);
							} else {
								druObj[dru] = { statuses: [key], faults: [] };
							}
						}
					});
			}
		}

		return druObj;
	};

	const buildDruList = (druStatus) => {
		if (!context.showStatus) {
			return Object.keys(druStatus).reduce((arr, dru) => [...arr, [dru, [], []]], []);
		}
		// else...
		const severityLevels = Object.values(SEVERITY);
		return Object.keys(druStatus).reduce((arr, dru) => {
			const { statuses, faults } = druStatus[dru];
			if (!!faults.length) {
				if (statuses.find((state) => severityLevels.includes(state)) === 'Noted') {
					const index = statuses.indexOf('Noted');
					if (index > -1) statuses.splice(index, 1);
					statuses.unshift('OK');
				}
			}

			return [...arr, [dru, statuses, faults]];
		}, []);
	};

	useEffect(() => {
		if (Object.keys(context.status).length) {
			const obj = drusObj(context.status['combined']);
			const list = buildDruList(obj);
			setDruList(list);
		}
	}, [context.currentDrus]);

	return (
		<div className={context.showStatus ? 'list-wrapper' : 'list-wrapper-extended'}>
            <DrusListHeader
                classname="dru-list-header"
                title="DRUs"
                searchTerm={searchTerm}
                onSearchTermChange={(e) => handleOnChange(e)}
                searchInputId="dru-search-field"
            />
			<ul ref={ref} className={context.showStatus ? 'dru-list' : 'dru-list-extended'}>
				{listContent()}
				<li
					key={'lastDruList'}
					className="dru-item"
					style={{
						minHeight: 26,
					}}
				>
					<span></span>
				</li>
			</ul>
		</div>
	);
};

export default DruList;
