/* Copyright Levelise Ltd 2024 */
import React from 'react';
import { SETTLEMENT_METHOD } from '../../utils/specification';

const getSettlementMethodDescription = (settlementMethod) => {
	if (typeof settlementMethod === 'string' || settlementMethod instanceof String) {
		// TODO: Remove this condition when '/fleet/facility/<facility_name>' endpoint
		//       switches the return value for settlementMethod from description to id.
		const id = Object.keys(SETTLEMENT_METHOD).find((key) => SETTLEMENT_METHOD[key].includes(settlementMethod));
		return !!id ? `${settlementMethod} (${id})` : settlementMethod;
	} else {
		return SETTLEMENT_METHOD.hasOwnProperty(settlementMethod)
			? SETTLEMENT_METHOD[settlementMethod]
			: settlementMethod;
	}
};

const CustomerFacilitySpecification = ({ facility, handleSeeMore }) => {
	if (Object.keys(facility).length) {
		return (
			<ul>
				<li>
					<span className="strong specification">Name </span>
					<span className="em specification">
						{facility.name}{' '}
						{facility?.name ? (
							<span
								style={{
									color: 'blue',
									textDecoration: 'underline',
									cursor: 'pointer',
									fontSize: 10,
									whiteSpace: 'nowrap',
								}}
								onClick={() => handleSeeMore(facility.name)}
							>
								See more
							</span>
						) : null}
					</span>
				</li>

				<li>
					<span className="strong customer">DRU ID</span>
					<span className="em customer">{facility?.druId ? facility.druId : '—'}</span>
				</li>

				<li>
					<span className="strong specification">Settlement Method </span>
					<span className="em specification">
						{facility?.settlementMethod ? getSettlementMethodDescription(facility.settlementMethod) : '—'}
					</span>
				</li>

				<li>
					<span className="strong specification">PV Net Capacity </span>
					<span className="em specification">{facility?.pvNetCapacityW || '—'} W</span>
				</li>

				<li>
					<span className="strong specification">Battery System</span>
					<span className="em specification">
						{Object.hasOwn(facility, 'batterySystem') ? facility.batterySystem.name : '—'}
					</span>
				</li>
				<li>
					<span className="strong specification">HW Tank </span>
					<span className="em specification">
						{Object.hasOwn(facility, 'hotWaterTank') ? facility.hotWaterTank?.name : '—'}
					</span>
				</li>
				<li>
					<span className="strong specification">DRU Setup </span>
					<span className="em specification">
						{!Object.hasOwn(facility, 'druNetwork') && !Object.hasOwn(facility, 'batteryLocation') && '—'}
						{`${Object.hasOwn(facility, 'druNetwork') ? facility.druNetwork?.name : ''}${
							Object.hasOwn(facility, 'batteryLocation')
								? ' (' + facility.batteryLocation?.name + ')'
								: ''
						}`}
					</span>
				</li>

				<li>
					<span className="strong specification">EV Charge Point ID </span>
					<span className="em specification">
						{Object.hasOwn(facility, 'evChargePointId') ? facility.evChargePointId : '—'}
					</span>
				</li>
				<li>
					<span className="strong specification">BS Unit </span>
					<span className="em specification">
						{facility?.aggregatedFacility ? facility.aggregatedFacility.name : '—'}
					</span>
				</li>
				<li>
					<span className="strong specification">Dispatch Contract </span>
					<span className="em specification">
						{facility?.aggregatedFacility
							? `${facility.aggregatedFacility.dispatch} ${facility.aggregatedFacility.unit}`
							: '—'}
					</span>
				</li>
			</ul>
		);
	}
};

export default CustomerFacilitySpecification;
