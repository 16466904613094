/* Copyright Levelise Ltd 2024 */
import React from 'react';
import TariffLineChart from '../LineChartsCustomer/charts/TariffLineChart';
import { suppliersIdNameObject } from '../LineChartsCustomer/charts/tariffHelper';

const convertMinuteToTime = (minutes) => {
	const hr = parseInt(minutes / 60);
	if (!isNaN(hr)) {
		const minute = minutes % 60;
		return `${String(hr).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;
	}

	return '';
};

const getFixedTwoDecimalPoints = (rate, standingCharge = false) => {
	if (rate || rate === 0) {
		return rate.toFixed(2) + (!standingCharge ? ' p/kWh' : ' p');
	}

	return '';
};

const getRateDescriptionByType = (type) => {
	if (type === 1) {
		return 'Daily single rate';
	}
	if (type === 2) {
		return 'Daily two rates';
	}
	if (type === 3) {
		return 'Half-hourly';
	}
	if (type === 4) {
		return 'Rates list';
	}

	return '';
};

const getCurrentRateForHH = (hhTarif) => {
	let currentRate = null;

	if (!hhTarif || !Object.hasOwn(hhTarif, 'timestampSec') || !Object.hasOwn(hhTarif, 'ratePerKwh')) {
		return currentRate;
	}
	const currentTime = new Date().getTime() / 1000;
	let i = 0;

	const timestamps = hhTarif?.timestampSec;

	// current tariff calculation based on current time
	while (!currentRate && i < timestamps?.length) {
		if (i === timestamps.length - 1) {
			currentRate = hhTarif?.ratePerKwh[i];
		} else if (currentTime >= timestamps[i] && currentTime < timestamps[i + 1]) {
			currentRate = hhTarif?.ratePerKwh[i];
		}

		i++;
	}

	currentRate = getFixedTwoDecimalPoints(currentRate);

	return currentRate;
};

const getCurrentRateForRatesList = (ratesList) => {
	let currentRate = null;

	if (!ratesList || !Object.hasOwn(ratesList, 'startMinute') || !Object.hasOwn(ratesList, 'ratePerKwh')) {
		return currentRate;
	}

	const dayStart = new Date();
	dayStart.setUTCHours(0, 0, 0, 0);
	const now = new Date().getTime();

	// minute elapsed since day start
	const currentMinute = Math.floor((now - dayStart.getTime()) / 60000);

	let i = 0;

	const timestamps = ratesList?.startMinute;

	// current tariff calculation based on current time
	while (!currentRate && i < timestamps?.length) {
		if (i === timestamps.length - 1) {
			currentRate = ratesList?.ratePerKwh[i];
		} else if (currentMinute >= timestamps[i] && currentMinute < timestamps[i + 1]) {
			currentRate = ratesList?.ratePerKwh[i];
		}

		i++;
	}

	currentRate = getFixedTwoDecimalPoints(currentRate);

	return currentRate;
};

const CustomerTariff = ({
	timezone,
	customerImportTariff,
	customerExportTariff,
}) => {
	const getExportTariffList = () => (
		<>
			<li>
				<span className="strong tariff">Export Type</span>
				<span className="em tariff">
					{customerExportTariff && Object.hasOwn(customerExportTariff, 'typeId')
						? getRateDescriptionByType(customerExportTariff.typeId)
						: '—'}
				</span>
			</li>
			<li>
				<span className="strong tariff">Export Supplier</span>
				<span className="em tariff">
					{customerExportTariff
						? customerExportTariff?.supplierName ||
						  suppliersIdNameObject[customerExportTariff.supplierId] ||
						  '—'
						: '—'}
				</span>
			</li>
			{/* Type 1 export */}
			{customerExportTariff && customerExportTariff?.typeId === 1 ? (
				<li>
					<span className="strong tariff">Export Rate</span>
					<span className="em tariff">
						{customerExportTariff && Object.hasOwn(customerExportTariff, 'dayRatePerKwh')
							? getFixedTwoDecimalPoints(customerExportTariff.dayRatePerKwh) || '—'
							: '—'}
					</span>
				</li>
			) : null}

			{/* Type 2 export */}
			{customerExportTariff && customerExportTariff?.typeId === 2 ? (
				<>
					<li>
						<span className="strong tariff">Day Rate</span>
						<span className="em tariff">
							{customerExportTariff && Object.hasOwn(customerExportTariff, 'dayRatePerKwh')
								? getFixedTwoDecimalPoints(customerExportTariff.dayRatePerKwh) || '—'
								: '—'}
						</span>
					</li>
					<li>
						<span className="strong tariff">Night Rate</span>
						<span className="em tariff">
							{customerExportTariff && Object.hasOwn(customerExportTariff, 'nightRatePerKwh')
								? getFixedTwoDecimalPoints(customerExportTariff.nightRatePerKwh) || '—'
								: '—'}
						</span>
					</li>
					<li>
						<span className="strong tariff">Night Start</span>
						<span className="em tariff">
							{customerExportTariff && Object.hasOwn(customerExportTariff, 'nightStartMinute')
								? convertMinuteToTime(customerExportTariff.nightStartMinute) || '—'
								: '—'}
						</span>
					</li>
					<li>
						<span className="strong tariff">Night End</span>
						<span className="em tariff">
							{customerExportTariff && Object.hasOwn(customerExportTariff, 'nightEndMinute')
								? convertMinuteToTime(customerExportTariff.nightEndMinute) || '—'
								: '—'}
						</span>
					</li>
				</>
			) : null}

			{customerExportTariff && customerExportTariff?.typeId === 3 ? (
				<li>
					<span className="strong tariff">Current Export Rate</span>
					<span className="em tariff">
						{customerExportTariff &&
						Object.hasOwn(customerExportTariff, 'hhRatesPerKwh') &&
						Object.hasOwn(customerExportTariff.hhRatesPerKwh, 'ratePerKwh') &&
						customerExportTariff.hhRatesPerKwh.ratePerKwh.length > 0
							? getCurrentRateForHH(customerExportTariff.hhRatesPerKwh) || '—'
							: '—'}
					</span>
				</li>
			) : null}

			{customerExportTariff && customerExportTariff?.typeId === 4 ? (
				<li>
					<span className="strong tariff">Current Export Rate</span>
					<span className="em tariff">
						{customerExportTariff && Object.hasOwn(customerExportTariff, 'dailyRatesPerKwh')
							? getCurrentRateForRatesList(customerExportTariff.dailyRatesPerKwh) || '—'
							: '—'}
					</span>
				</li>
			) : null}

			{customerExportTariff && customerExportTariff?.name ? (
				<li>
					<span className="strong tariff">Export Tariff Name</span>
					<span className="em tariff">{customerExportTariff?.name || '—'}</span>
				</li>
			) : null}
		</>
	);

	const getTariffList = () => (
		<>
			<li>
				<span className="strong tariff">Type</span>
				<span className="em tariff">
					{customerImportTariff && Object.hasOwn(customerImportTariff, 'typeId')
						? getRateDescriptionByType(customerImportTariff.typeId)
						: '—'}
				</span>
			</li>
			<li>
				<span className="strong tariff">Supplier</span>
				<span className="em tariff">
					{customerImportTariff
						? customerImportTariff?.supplierName ||
						  suppliersIdNameObject[customerImportTariff.supplierId] ||
						  '—'
						: '—'}
				</span>
			</li>

			{/* Type 1 import */}
			{customerImportTariff &&
			Object.hasOwn(customerImportTariff, 'typeId') &&
			customerImportTariff.typeId === 1 ? (
				<li>
					<span className="strong tariff">Day Rate</span>
					<span className="em tariff">
						{customerImportTariff && Object.hasOwn(customerImportTariff, 'dayRatePerKwh')
							? getFixedTwoDecimalPoints(customerImportTariff.dayRatePerKwh) || '—'
							: '—'}
					</span>
				</li>
			) : null}

			{/* Type 2 tariff import */}
			{customerImportTariff &&
			Object.hasOwn(customerImportTariff, 'typeId') &&
			customerImportTariff.typeId === 2 ? (
				<>
					<li>
						<span className="strong tariff">Day Rate</span>
						<span className="em tariff">
							{customerImportTariff && Object.hasOwn(customerImportTariff, 'dayRatePerKwh')
								? getFixedTwoDecimalPoints(customerImportTariff.dayRatePerKwh) || '—'
								: '—'}
						</span>
					</li>
					<li>
						<span className="strong tariff">Night Rate</span>
						<span className="em tariff">
							{customerImportTariff && Object.hasOwn(customerImportTariff, 'nightRatePerKwh')
								? getFixedTwoDecimalPoints(customerImportTariff.nightRatePerKwh) || '—'
								: '—'}
						</span>
					</li>
					<li>
						<span className="strong tariff">Night Start</span>
						<span className="em tariff">
							{customerImportTariff && Object.hasOwn(customerImportTariff, 'nightStartMinute')
								? convertMinuteToTime(customerImportTariff.nightStartMinute)
								: '—'}
						</span>
					</li>
					<li>
						<span className="strong tariff">Night End</span>
						<span className="em tariff">
							{customerImportTariff && Object.hasOwn(customerImportTariff, 'nightEndMinute')
								? convertMinuteToTime(customerImportTariff.nightEndMinute)
								: '—'}
						</span>
					</li>
				</>
			) : null}

			{/* Type 3 import */}
			{customerImportTariff &&
			Object.hasOwn(customerImportTariff, 'typeId') &&
			customerImportTariff.typeId === 3 ? (
				<li>
					<span className="strong tariff">Current Import Rate</span>
					<span className="em tariff">
						{customerImportTariff &&
						Object.hasOwn(customerImportTariff, 'hhRatesPerKwh') &&
						Object.hasOwn(customerImportTariff.hhRatesPerKwh, 'ratePerKwh') &&
						customerImportTariff.hhRatesPerKwh.ratePerKwh.length > 0
							? getCurrentRateForHH(customerImportTariff.hhRatesPerKwh) || '—'
							: '—'}
					</span>
				</li>
			) : null}

			{/* Type 4 import */}
			{customerImportTariff &&
			Object.hasOwn(customerImportTariff, 'typeId') &&
			customerImportTariff.typeId === 4 ? (
				<li>
					<span className="strong tariff">Current Import Rate</span>
					<span className="em tariff">
						{customerImportTariff &&
						Object.hasOwn(customerImportTariff, 'dailyRatesPerKwh') &&
						Object.hasOwn(customerImportTariff.dailyRatesPerKwh, 'ratePerKwh')
							? getCurrentRateForRatesList(customerImportTariff.dailyRatesPerKwh) || '—'
							: '—'}
					</span>
				</li>
			) : null}

			<li>
				<span className="strong tariff">Daily Standing Charge</span>
				<span className="em tariff">
					{customerImportTariff && Object.hasOwn(customerImportTariff, 'dailyStandingCharge')
						? getFixedTwoDecimalPoints(customerImportTariff.dailyStandingCharge, true)
						: '—'}
				</span>
			</li>
			{customerImportTariff && Object.hasOwn(customerImportTariff, 'details') ? (
				<li>
					<span className="strong tariff">Details</span>
					<span className="em tariff">{customerImportTariff.details || '—'}</span>
				</li>
			) : null}
			<li>
				<span className="strong tariff">Name</span>
				<span className="em tariff">
					{customerImportTariff && Object.hasOwn(customerImportTariff, 'name')
						? customerImportTariff.name
						: '—'}
				</span>
			</li>
		</>
	);

	return (
		<div className="customer-info-container">
			{getTariffList()}

			<div>
				<TariffLineChart
					importTariff={customerImportTariff}
					timezone={timezone}
					exportTariff={customerExportTariff}
				/>
			</div>

			{getExportTariffList()}
		</div>
	);
};

export default CustomerTariff;
