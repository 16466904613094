/* Copyright Levelise Ltd 2019-2024 */
import React, { useContext, useEffect } from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import 'chartjs-adapter-date-fns';
import zoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin from 'chartjs-plugin-annotation';

import IdleService from '../../services/idle-service';
import TokenService from '../../services/token-service';
import UserService from '../../services/user-service';
import { detectBrowser, hasPermission } from '../../utils/utils';
import { verticalLinePlugin } from '../../utils/chart';
import './App.css';

// Routes
import AuthRoute from '../../routes/AuthRoute/index';
import FleetRoute from '../../routes/FleetRoute/index';
import FacilityRoute from '../../routes/FacilityRoute/index';
import ConfigurationRoute from '../../routes/ConfigureRoute/index';
import ErrorRoute from '../../routes/ErrorRoute';
import Route404 from '../../routes/404Route/index';

import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
} from 'chart.js';
import { PERMISSIONS } from '../../utils/constants';
import CustomerRoute from '../../routes/CustomerRoute';
import OAuthService from '../../services/auth-service';
import FacilityConext from '../../contexts/FacilityContext';
import FleetContext from '../../contexts/FleetContext';
import UserContext from '../../contexts/UserContext';
import AddCustomerRoute from '../../routes/AddCustomerRoute';
import UserRoute from '../../routes/UserRoute';
import FleetCustomersRoute from '../../routes/FleetCustomersRoute';
import FleetStatusContext from '../../contexts/FleetStatusContext';
import OrdersRoute from '../../routes/OrdersRoute';


Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle,
    annotationPlugin,
    zoomPlugin,
    verticalLinePlugin,
);


const UserAccessedRoute = ({ children }) => {
    return UserService.hasUser() ? children : <Navigate to="/auth" />;
};

const ProviderRoute = ({ children }) => {
    return UserService.hasUser() && !UserService.getUser().isRoleUser ? children : <Navigate to="/auth" />;
};

// UserService.isAdmin()
const AdminRoute = ({ children }) => {
    if (hasPermission(PERMISSIONS.CAN_CREATE_FACILITY)) {
        return children;
    }

    return <Navigate to="/auth" />
};

const CustomerAccessRoute = ({ children }) => {
    if (hasPermission(PERMISSIONS.CAN_ACCESS_CUSTOMER)) {
        return children;
    }

    return <Navigate to="/auth" />
};

const SupportAccessRoute = ({ children }) => {
    if (hasPermission(PERMISSIONS.CAN_CONTROL_DRU)) {
        return children;
    }

    return <Navigate to="/auth" />
};

const App = () => {
    const fleetContext = useContext(FleetContext);
    const fleetStatusContext = useContext(FleetStatusContext);
    const facilityContext = useContext(FacilityConext);
    const userContext = useContext(UserContext);

    const navigate = useNavigate();

    useEffect(() => {
        return () => {
            IdleService.unRegisterIdleResets();
            TokenService.clearAuthToken();
            UserService.removeUser();
            UserService.removeUserDetail();
            TokenService.clearCallbackBeforeExpiry();
        }
    }, []);

    useEffect(() => {
        if (TokenService.getAuthToken()) {
            TokenService.queueCallbackBeforeExpiry(() => OAuthService.postRefreshToken());

            IdleService.setIdleCallback(() => {
                userContext.clearUser();
                fleetContext.clearState();
                fleetStatusContext.clearState();
                facilityContext.clearState();
                IdleService.unRegisterIdleResets();
                UserService.removePreferences();
                UserService.removeUser();
                TokenService.clearAuthToken();
                TokenService.clearCallbackBeforeExpiry();
                navigate('/auth');
            });
            
            IdleService.resetIdleTimer();

            IdleService.registerIdleTimerResets();
        }
    }, []);


    return (
        <div className="App" id={detectBrowser()}>
            <Routes>
                <Route path='/' element={<Navigate replace to='/auth' />} />
                <Route path='/auth' element={<AuthRoute />} />
                <Route path='/fleet' element={<ProviderRoute><FleetRoute /></ProviderRoute>} />
                <Route path='/fleet-customers' element={<CustomerAccessRoute><FleetCustomersRoute /></CustomerAccessRoute>} />
                <Route path='/orders' element={<SupportAccessRoute><OrdersRoute /></SupportAccessRoute>} />
                <Route path='/customer' element={<CustomerAccessRoute><CustomerRoute /></CustomerAccessRoute>} />
                <Route path='/add-customer' element={<CustomerAccessRoute><AddCustomerRoute /></CustomerAccessRoute>} />
                <Route path='/facility/:facilityName' element={<UserAccessedRoute><FacilityRoute /></UserAccessedRoute>} />
                <Route path='/user' element={<UserAccessedRoute><UserRoute /></UserAccessedRoute>} />
                <Route path='/configure-facility' element={<AdminRoute><ConfigurationRoute /></AdminRoute>} />
                <Route path='/error-page' element={<ErrorRoute />} />
                <Route path='*' element={<Route404 />} />
            </Routes>
        </div>
    );
}

export default App;
