/* Copyright Levelise Ltd 2024 */
import React, { useState } from 'react';
import { SpecInput, SpecLabel } from '../Form';
import { validateEmail } from '../../utils/utils';
import CustomerFacilitySpecification from '../CustomerFacilitySpecification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const CustomerFacilities = React.memo(({ facilities, handleSeeMore, onCustomerInfo }) => {
	return (
		<ul
			className={`${
				onCustomerInfo ? 'collapsable-facilities-list' : 'customer-info-container'
			} customer-facility-container`}
			data-cy="customer-facility-container"
		>
			{facilities &&
				facilities.length > 0 &&
				facilities.map((facility, index) => (
					<CustomerFacilitySpecification key={index} facility={facility} handleSeeMore={handleSeeMore} />
				))}
		</ul>
	);
});

const CustomerInfoTab = ({
	customer,
	email,
	handleEmailChange,
	forename,
	handleForenameChange,
	surname,
	handleSurnameChange,
	editing,
	onCheckboxClick,
	updateUser,
	userExistsForCustomer,
	noAddress,
	addressOnly,
	containerStyle,
	withFacilities,
	facilities,
	handleSeeMore,
}) => {
	const hasCustomer = customer && !!Object.keys(customer).length;

	const [showFacilities, setShowFacilities] = useState(true);

	return (
		<ul className="customer-info-container" style={containerStyle} data-cy="customer-info-container">
			{addressOnly ? null : (
				<>
					<li>
						<span className="strong customer">Customer ID</span>
						<span className="em customer">
							{hasCustomer && customer?.customerId ? customer.customerId : '—'}
						</span>
					</li>
					<li>
						<span className="strong customer">Forename</span>
						<span className="em customer" data-cy="customer-forename">
							{!editing ? (
								hasCustomer && customer?.name ? (
									customer.name
								) : (
									'—'
								)
							) : (
								<div className="email-input-checkbox-container">
									<SpecInput
										className="info-input-customer"
										name="idInput"
										type="text"
										placeholder="Forename"
										value={forename}
										onChange={handleForenameChange}
										data-cy="customer-forename-input"
									/>
								</div>
							)}
						</span>
					</li>
					<li>
						<span className="strong customer">Surname</span>
						<span className="em customer" data-cy="customer-surname">
							{!editing ? (
								hasCustomer && customer?.surname ? (
									customer.surname
								) : (
									'—'
								)
							) : (
								<div className="email-input-checkbox-container">
									<SpecInput
										className="info-input-customer"
										name="idInput"
										type="text"
										placeholder="Surname"
										value={surname}
										onChange={handleSurnameChange}
										data-cy="customer-surname-input"
									/>
								</div>
							)}
						</span>
					</li>
				</>
			)}

			{noAddress ? null : (
				<>
					<li>
						<span className="strong customer">Address Line 1</span>
						<span className="em customer">
							{hasCustomer && customer?.address1 ? customer.address1 : '—'}
						</span>
					</li>
					<li>
						<span className="strong customer">Address Line 2</span>
						<span className="em customer">
							{hasCustomer && customer?.address2 ? customer.address2 : '—'}
						</span>
					</li>
					<li>
						<span className="strong customer">City</span>
						<span className="em customer">{hasCustomer && customer?.city ? customer.city : '—'}</span>
					</li>
					<li>
						<span className="strong customer">County</span>
						<span className="em customer">{hasCustomer && customer?.county ? customer.county : '—'}</span>
					</li>
					<li>
						<span className="strong customer">Postcode</span>
						<span className="em customer">
							{hasCustomer && customer?.postcode ? customer.postcode : '—'}
						</span>
					</li>
				</>
			)}

			{addressOnly ? null : (
				<>
					<li>
						<span className="strong customer">Phone</span>
						<span className="em customer">{hasCustomer && customer?.phone ? customer.phone : '—'}</span>
					</li>
					{/* used withFacilities flag to check if we are on customer info section */}
					{withFacilities && hasCustomer && customer?.mobile && customer.mobile !== customer?.phone ? (
						<li>
							<span className="strong customer">Mobile</span>
							<span className="em customer">{customer.mobile || '—'}</span>
						</li>
					) : null}
					<li>
						<span className="strong customer">Email</span>

						<span className="em customer" data-cy="customer-email">
							{!editing ? (
								hasCustomer && customer?.email ? (
									customer.email
								) : (
									'—'
								)
							) : (
								<div className="email-input-checkbox-container">
									<SpecInput
										className="info-input-customer"
										name="idInput"
										type="text"
										placeholder="example@example.com"
										value={email}
										onChange={handleEmailChange}
										style={{
											borderColor: validateEmail(email) ? '#808080' : '#a81916',
										}}
										data-cy="customer-email-input"
									/>
									{userExistsForCustomer ? (
										<div className="email-checkbox-lable-container">
											<SpecInput
												type="checkbox"
												id="updateUserEmail"
												name="updateUserEmail"
												className="update-user-email"
												onChange={onCheckboxClick}
												checked={updateUser}
											/>
											<SpecLabel className="update-user-email-label" htmlFor="updateUserEmail">
												Update user email
											</SpecLabel>
										</div>
									) : null}
								</div>
							)}
						</span>
					</li>
				</>
			)}

			{withFacilities ? (
				<>
					<li style={{ minHeight: 25, alignItems: 'center' }}>
						<span
							className="strong customer"
							onClick={() => setShowFacilities(!showFacilities)}
							style={{ cursor: 'pointer', fontSize: '0.8rem' }}
						>
							{showFacilities ? (
								<FontAwesomeIcon
									icon={faChevronDown}
									style={{ marginRight: 5, fontSize: 10, marginBottom: 1, color: '#444444' }}
								/>
							) : (
								<FontAwesomeIcon
									icon={faChevronRight}
									style={{ marginRight: 5, fontSize: 10, marginBottom: 1, color: '#444444' }}
								/>
							)}
							Facilities
						</span>
						<span className="em customer"> </span>
					</li>
					{showFacilities ? (
						<CustomerFacilities facilities={facilities} handleSeeMore={handleSeeMore} onCustomerInfo />
					) : null}
				</>
			) : null}
			<li>
				<span className="strong customer"></span>
				<span className="em customer">&nbsp;</span>
			</li>
		</ul>
	);
};

export default CustomerInfoTab;
