/* Copyright Levelise Ltd 2024 */
import React, { useState, useRef } from 'react';
import '../CustomerList/index.css';
import './index.css';
import FleetContext from '../../contexts/FleetContext';
import FleetService from '../../services/fleet-service';
import UserService from '../../services/user-service';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { Button, SpecInput } from '../Form';
import { useMemo } from 'react';
import { useCallback } from 'react';
import TabContainer from '../TabContainer';
import AddBtn from '../AddBtn';
import { useAlert } from 'react-alert';

const FleetCustomerList = () => {
	const navigate = useNavigate();

	const alert = useAlert();

	const fleetContext = useContext(FleetContext);

	const [loading, setLoading] = useState(false);

	const ref = useRef(0);

	const setCustomerIds = (txt) => fleetContext.setFleetCustomerIds(txt);
	const setCustomerSearchTerm = (txt) => fleetContext.setFleetCustomerSearchTerm(txt);
	const setCustomers = (arr) => fleetContext.setFleetCustomersList(arr);

	const onCustomerClicked = useCallback(
		(customer) => {
			fleetContext.setCustomerSelectedInfoTab('Info');
			navigate('/customer', { state: { customer } });
		},
		[navigate]
	);

	const onDruSelected = useCallback(async (dru) => {
		if (typeof dru === 'undefined' || dru === null || dru === '') {
			return;
		}

		let facilityName = '';

		try {
			const facility = await FleetService.getFacilityByDruId(dru);
			if (facility && Object.hasOwn(facility, 'name')) {
				facilityName = facility.name;
				const customer = await FleetService.getCustomerByFacility(facility.name);
				if (customer) {
					navigate('/customer', { state: { customer } });
				}
			} else {
				alert.show("Couldn't find selected dru's facility information.");
			}
		} catch (err) {
			if (err.status === 404 && err.message === `No customer was found for facility ${facilityName}` ) {
				alert.show("Couldn't find selected facility's customer.");
			} else {
				alert.show('Something went wrong, please try again.');
			}
		}
	}, [navigate]);

	const getCustomersList = useCallback(() => {
		if (fleetContext && fleetContext?.fleetCustomersList && fleetContext?.fleetCustomersList?.length > 0) {
			return fleetContext.fleetCustomersList?.map((customer, index) => (
				<li
					key={index}
					style={{
						cursor: 'pointer',
					}}
				>
					<span className="strong customer" onClick={() => onCustomerClicked(customer)}>
						{customer?.name} {customer?.surname}
					</span>
					<span className="subtitle customer" onClick={() => onCustomerClicked(customer)}>
						{customer?.address1}
					</span>
					<span className="subtitle customer" onClick={() => onCustomerClicked(customer)}>
						{customer?.city}
					</span>
				</li>
			));
		}
	}, [onCustomerClicked, fleetContext]);

	const getCustomerDrusList = useCallback(
		(drus) => {
			if (drus && drus?.length > 0) {
				return drus?.map((dru, index) => (
					<li
						key={`${index}dru`}
						style={{
							cursor: 'pointer',
						}}
					>
						<span className="strong customer" onClick={() => onDruSelected(parseInt(dru))}>
							{dru}
						</span>
					</li>
				));
			}
		},
		[onDruSelected]
	);

	const customersList = useMemo(() => getCustomersList(), [getCustomersList]);

	const drusList = useMemo(
		() => getCustomerDrusList(fleetContext.customerDrusRes),
		[fleetContext.customerDrusRes, getCustomerDrusList]
	);

	const includes = (dru) => {
		if (!fleetContext.customerSearchDruId.length) return false;
		// else...
		const match = fleetContext.customerSearchDruId.toLowerCase();

		if (String(dru).includes(match)) return true;

		return false;
	};

	const getList = (druList) => {
		if (druList && druList.length) {
			const filteredList = druList.filter((dru) => includes(dru)).sort((a, b) => parseInt(a) - parseInt(b));
			const cleanList = Array.from(new Set(filteredList));
			return cleanList;
		}

		return [];
	};

	const onSearchClicked = async () => {
		setLoading(true);

		if (fleetContext.customerSearchDruId.length > 0) {
			const listOfDru = getList(fleetContext.currentDrusForCustomerSearch);
			console.log(listOfDru)
			fleetContext.setCustomerDrusRes(listOfDru);
			fleetContext.setCustomerSearchShowDrusList(true);
			setLoading(false);

			return;
		}

		try {
			setCustomers([]);
			fleetContext.setCustomerSearchShowDrusList(false);

			const res = await FleetService.getUserCustomers(UserService.getUsername(), fleetContext.fleetCustomerIds, fleetContext.fleetCustomerSearchTerm);

			if (res && Object.hasOwn(res, 'customers') && res.customers?.length > 0) {
				setCustomers(res.customers);
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
		}
	};

	const onClearClicked = () => {
		setCustomers([]);
		setCustomerIds('');
		setCustomerSearchTerm('');
		fleetContext.clearCustomerDrusRes();
		fleetContext.clearCustomerSearchDruId();
		fleetContext.setCustomerSearchShowDrusList(false);
	};

	const onIdsChange = (e) => {
		setCustomerIds(e.target.value);
	};

	const onSearchTermChange = (e) => {
		setCustomerSearchTerm(e.target.value);
	};

	const onSearchDruIdChange = (e) => {
		fleetContext.setCustomerSearchDruId(e.target.value);
	};

	const onAddCustomerClick = () => {
		navigate('/add-customer');
	};

	return (
		<TabContainer title="Customers" rightCornerComponent={<AddBtn onAddClick={onAddCustomerClick} />}>
			<div className="search-inputs-container">
				<div className="label-input-container">
					<label htmlFor="idInput">ID</label>
					<SpecInput
						id="customer-id-input"
						name="idInput"
						type="text"
						placeholder="Customer ID e.g. 12"
						value={fleetContext.fleetCustomerIds}
						onChange={onIdsChange}
						disabled={fleetContext.customerSearchDruId.length > 0}
					/>
				</div>
				<div className="label-input-container">
					<label htmlFor="searchInput">Search</label>
					<SpecInput
						id="customer-info-input"
						name="searchInput"
						type="text"
						placeholder="Name/Address/Email/Phone"
						value={fleetContext.fleetCustomerSearchTerm}
						onChange={onSearchTermChange}
						disabled={fleetContext.customerSearchDruId.length > 0}
					/>
				</div>
				<div className="label-input-container">
					<label htmlFor="searchInput">DRU ID</label>
					<SpecInput
						id="customer-info-input-dru"
						name="searchInputDruIs"
						type="number"
						placeholder="790..."
						value={fleetContext.customerSearchDruId}
						onChange={onSearchDruIdChange}
						disabled={fleetContext.fleetCustomerIds || fleetContext.fleetCustomerSearchTerm}
					/>
				</div>
				<div className="customer-search-button-container">
					<Button className="configure-btn reset" onClick={onClearClicked}>
						Clear
					</Button>
					<Button
						className={'configure-btn submit'}
						disabled={loading || !(fleetContext.fleetCustomerIds || fleetContext.fleetCustomerSearchTerm || fleetContext.customerSearchDruId)}
						onClick={onSearchClicked}
					>
						Search
					</Button>
				</div>
			</div>
			<ul ref={ref} className={'fleet-customer-list'} data-cy="fleet-customer-search-results">
			{!fleetContext.customerSearchShowDrusList ? <>{customersList}</> : null}
				
			{fleetContext.customerDrusRes.length > 0 && fleetContext.customerSearchShowDrusList ? drusList : null}
					<li
						key={'lastCustomer'}
						style={{
							cursor: 'pointer',
						}}
					>
						<span className="strong customer"></span>
					</li>
			</ul>
		</TabContainer>
	);
};

export default FleetCustomerList;
