/* Copyright Levelise Ltd 2019-2023 */
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useAlert } from 'react-alert';
import cx from 'classnames';
import config from '../../config';
import FleetService from '../../services/fleet-service';
import UserService from '../../services/user-service';
import FleetContext from '../../contexts/FleetContext';
import FacilityContext from '../../contexts/FacilityContext';
import DruList from '../../components/DruList/index';
import Status from '../../components/Status/index';
import AggregatedFacilities from '../../components/AggFacilities/index';
import Facilities from '../../components/Facilities/index';
import NavBar from '../../components/NavBar/index';
import LineChartsFleet from '../../components/LineChartsFleet';
import { handleError } from '../../services/api-error-service';
import { PERMISSIONS, routes, fleet, show } from '../../utils/constants';
import { hasPermission, combineStatus } from '../../utils/utils';
import './index.css';
import CustomerList from '../../components/CustomerList/CustomerList';

const FleetRoute = () => {
	const alert = useAlert();
	const navigate = useNavigate();
	const fleetContext = useContext(FleetContext);
	const facilityContext = useContext(FacilityContext);
	const [status, setStatus] = useState(show);
	const [errorStatus, SetErrorStatus] = useState();

	const handleFetchDruStatuses = () => {
		FleetService.getDruStatuses().then(handleStatus).catch(fleetContext.setError);
	};

	const handleFetchFaultCodes = () => {
		if (!Object.keys(fleetContext.faultCodes).length) {
			FleetService.getFaultCode().then(fleetContext.setFaultCodes).catch(fleetContext.setError);
		}
	};

	const handleFetchAggFacilities = () => {
		if (!fleetContext.aggFacilities.length) {
			FleetService.getAfs()
				.then((res) => {
					const members = {};
					let afs = res.afs;
					for (let i = 0; i < afs.length; i++) {
						const constituents = afs[i].constituents;
						constituents?.sort((a, b) => a.localeCompare(b));
						if (!!constituents) {
							for (let j = 0; j < afs.length; j++) {
								const index = constituents.indexOf(afs[j].name);
								if (index >= 0) {
									afs[i].constituents[index] = afs[j];
									members[afs[j].name] = {
										...afs[j],
										parents: members[afs[j].name]?.parents
											? [...members[afs[j].name]?.parents, afs[i]?.name]
											: [afs[i]?.name],
									};
								}
							}
						}
					}

					const membersKeyArray = Object.keys(members);

					afs = afs.filter((af) => !membersKeyArray.includes(af.name));
					afs = [...afs, ...Object.values(members)];
					fleetContext.setAggregatedFacilities(afs);
					fleetContext.setTimezone(res.fleetTimezone);
					localStorage.setItem('timezone', res.fleetTimezone);
				})
				.catch(fleetContext.setError);
		}
	};

	const handleStatus = (druStatus) => {
		const [drus, status] = combineStatus(druStatus);
		fleetContext.setStatus(status);
		fleetContext.setCurrentDrus(drus);
	};

	const directTo = (facility) => {
		handleFetchSpecification(facility);
	};

	const handleFetchSpecification = (facility) => {
		if (!!facility && facility !== 'undefined') {
			FleetService.getFacility(facility)
				.then((res) => {
					if (Object.keys(res).length > 1) {
						facilityContext.setFacility(res);
						navigate(`/facility/${facility}`);
					} else {
						// NOTE: Response body only contains DRU ID if specification is not set
						alert.show('Facility not found');
					}
				})
				.catch(facilityContext.setError);
		}
	};

	const getAllDrus = () => {
		let drus = [];
		if (fleetContext.status.hasOwnProperty('combined')) {
			for (let key in fleetContext.status['combined']) {
				const value = fleetContext.status['combined'][key];
				if (typeof value === 'object' && value !== null) {
					switch (key) {
						case 'disconnected':
							const disconnectedDrus = value.map((dru) => dru['druId']);
							drus.push(...disconnectedDrus);
							break;
						case 'faultCode':
							const faultCodeDrus = Object.values(value).reduce((list, drus) => [...list, ...drus], []);
							drus.push(...faultCodeDrus);
							break;
						case 'severestFault':
							break;
						case 'severestFaultCount':
							break;
						default:
							drus.push(...value);
					}
				}
			}
		}

		return drus;
	};

	useEffect(() => {
		if (
			!fleetContext.showStatus &&
			fleetContext.currentDataType === fleet &&
			Object.keys(fleetContext.status).length
		) {
			fleetContext.setCurrentDrus(getAllDrus());
		}
	}, [fleetContext.currentDataType]);

	useEffect(() => {
		const error = fleetContext.error;
		if (!!error && handleError(error)) {
			SetErrorStatus(error.status);
		}
	}, [fleetContext.error]);

	useEffect(() => {
		if (UserService.hasUser() && !!UserService.getUser().role && hasPermission(PERMISSIONS.CAN_ACCESS_DR_BATTERY) && !fleetContext?.currentDataType) {
			fleetContext.setCurrentDataType(fleet);
		}

		if (UserService.hasPreferences()) {
			const preference = UserService.getPreferences();
			setStatus(preference[config.status]);
			fleetContext.setShowStatus(preference[config.status] === show);
		}

		handleFetchFaultCodes();
		handleFetchDruStatuses();
		if (hasPermission(PERMISSIONS.CAN_ACCESS_AF)) {
			handleFetchAggFacilities();
		}

		const interval = setInterval(() => handleFetchDruStatuses(), 10 * 60 * 1000);
		return () => clearInterval(interval);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * 
	 * @param {*} batterySystems 
	 * @returns Obj {meterTypeId: { name, bsIds: [bsId1, bsId2,...]}}
	 */
	const getMeteringTypes = (batterySystems) => {
		const meteringTypes = batterySystems.reduce((acc, curr) => {
			if (Object.hasOwn(acc, curr?.meteringType?.id)) {
				acc[curr.meteringType.id].bsIds = [...acc[curr.meteringType.id].bsIds, curr.id]
			} else {
				acc[curr.meteringType.id] = {
					name: curr.meteringType.name,
					bsIds: [curr.id]
				}
			}

			return acc;
		}, {});

		return meteringTypes;
	}

	const handleFetchSystems = () => {
        if (UserService.hasUser() && hasPermission(PERMISSIONS.CAN_ACCESS_AF)) {
            FleetService.getBatterySystemDrus()
                .then(res => fleetContext.setBatterySystemDrus(res))
                .catch(fleetContext.setError);

            FleetService.getRegionalDrus()
                .then(res => fleetContext.setRegionDrus(res))
                .catch(fleetContext.setError);

            FleetService.getSettlementMethodDrus()
                .then(res => fleetContext.setSettlementMethodDrus(res))
                .catch(fleetContext.setError);

            FleetService.getBatterySystems()
                .then(res => {
                    const batterySystems = res.reduce((acc, curr) => {
                        acc[curr.id] = curr.name;
                        return acc;
                    }, { 0: '-- None --' });

					const meteringTypes = getMeteringTypes(res);
					
                    fleetContext.setBatterySystemSpecsList(res);
                    fleetContext.setBatterySystems(batterySystems);
					fleetContext.setMeteringTypes(meteringTypes);
                })
                .catch(fleetContext.setError);

            FleetService.getHwTanks()
                .then(res => {
                    const hwTanks = res.reduce((acc, curr) => {
                        acc[curr.id] = curr.name;
                        return acc;
                    }, { 0: '-- None --' });
                    fleetContext.setHwSpecsList(res);
                    fleetContext.setHwTanks(hwTanks);
                })
                .catch(fleetContext.setError);
        }
    }

	useEffect(()=>{
		handleFetchSystems();
	}, []);

	const renderDrusOrFacilities = () => {
		if (fleetContext.currentDataType === fleet || fleetContext.currentDataType === '') {
			return <DruList directTo={directTo} />;
		} else {
			return (
				<Facilities
					facilities={JSON.parse(JSON.stringify(fleetContext.facilities))}
					aggregatedFacility={fleetContext.currentDataType}
					directTo={directTo}
				/>
			);
		}
	};

	if (errorStatus) {
		navigate(`/error-page`);
	}

	return (
		<>
			<NavBar route={routes.fleet} />
			<section className={cx('fleet-route', fleetContext.showStatus ? 'show-status' : '')}>
				<div className={cx('fleet-status',  fleetContext.showStatus ? '' : 'status-hidden')}>
					<div className='customer-af-container' style={{
						display: 'flex',
					}}>
						{hasPermission(PERMISSIONS.CAN_ACCESS_CUSTOMER) && <CustomerList />}
						{hasPermission(PERMISSIONS.CAN_ACCESS_AF) && <AggregatedFacilities />}
						
					</div>
					<div className="fleet-graph-container-wide">
						{hasPermission(PERMISSIONS.CAN_ACCESS_AF) && <LineChartsFleet />}
					</div>
					<div className='status-drus-container' style={{
						display: 'flex',
						flexGrow: 1
					}}>
						{status === show && <Status />}
						<div style={{
							flexGrow: 1
						}}>{renderDrusOrFacilities()}</div>
					</div>
				</div>
				<div className="fleet-graph-container-narrow">
					{hasPermission(PERMISSIONS.CAN_ACCESS_AF) && <LineChartsFleet />}
				</div>
			</section>
		</>
	);
};

export default FleetRoute;
