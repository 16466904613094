/* Copyright Levelise Ltd 2024 */
import React, { useCallback, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { getZonedTimestamp, mapSingleRateTariff, mapTypeFourTariff, mapTypeThreeTariff, mapTypeTwoTariff } from './tariffHelper';
import {
	CONTRACT_ANNOTATION,
	customerChartTitles,
	dataset,
	getTimeFormat,
	options,
	tariffExport,
	tariffImport,
	tooltipTitleCallbacks,
	xAxis,
} from '../../../utils/chart';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { startOfDay } from 'date-fns';

const x = { type: 'time' };

const y = {
	stacked: false,
	ticks: {},
	label: 'Tariff (p/kWh)',
	gridLines: { drawOnChartArea: true },
};

const legendLabels = {
	font: { size: 10 },
	color: 'black',
	usePointStyle: true,
	boxWidth: 4,
	boxHeight: 4,
	padding: 8,
	filter: function (item, chart) {
		const idx = item.datasetIndex;
		const dataset = chart.datasets[idx];
		return !dataset.hidden;
	},
};

function TariffLineChart({ importTariff, timezone, exportTariff }) {
	const [chart, setChart] = useState({
		data: {
			labels: [],
			datasets: [
				dataset('line', tariffImport.type, tariffImport.backgroundColor, tariffImport.borderColor, 'y'),
				dataset('line', tariffExport.type, tariffExport.backgroundColor, tariffExport.borderColor, 'y'),
			],
		},
		options: options("Today's Tariff", legendLabels, x, null, y, null, null),
	});

	const getImportTariffData = useCallback((importTariffObject, halfHourlyImport) => {
		let impTariff = [];
		if (importTariffObject?.typeId === 1) {
			const { dayRatePerKwh } = importTariffObject;
			impTariff = mapSingleRateTariff(dayRatePerKwh);
		} else if (importTariffObject?.typeId === 2) {
			impTariff = mapTypeTwoTariff(importTariffObject);
		} else if (importTariffObject?.typeId === 3) {
			if (halfHourlyImport) {
				impTariff = mapTypeThreeTariff(halfHourlyImport);
			}
		} else if (importTariffObject?.typeId === 4) {
			if (Object.hasOwn(importTariffObject, 'dailyRatesPerKwh')) {
				const { dailyRatesPerKwh } = importTariffObject;
				impTariff = mapTypeFourTariff(dailyRatesPerKwh);
			}
		}
		return impTariff;
	}, []);

	const handleSetChart = (labels, datasets, time, annotation, tz = 'Europe/London') => {
		const maxAbs = Math.abs(Math.max(...datasets.map((d, i) => (i !== 5 ? Math.max(...d.data) : 0))));
		const minValue = Math.min(...datasets.map((d, i) => (i !== 5 ? Math.min(...d.data) : 0)));
		const minAbs = Math.abs(minValue);
		const max = maxAbs > minAbs ? maxAbs : minAbs;
		const min = minValue >= 0 ? 0 : parseInt((minValue - 5) / 5) * 5;
		const timeFrame = '24 hours';

		let range = parseInt((max + 5) / 5) * 5;

		if (range === 0) range = 1;

		const update = {
			data: { labels: labels, datasets: datasets },
			options: {
				...chart.options,
				plugins: {
					...chart.options.plugins,
					annotation: { annotations: annotation },
					title: { display: true, text: "Today's Tariff", position: 'top' },
					tooltip: { ...chart.options.plugins.tooltip, callbacks: tooltipTitleCallbacks(tz) },
				},
				scales: {
					...chart.options.scales,
					y: { ...chart.options.scales.y, min: min, max: range },
					x: xAxis(timeFrame, time, tz),
				},
			},
		};

		setChart(update);
	};

	const getGraphData = useCallback(() => {
		try {
			if (importTariff) {
				const tz = timezone || 'Europe/London';
				const date = new Date();

				const zonedDate = utcToZonedTime(date, tz);
				const startOfZonedDate = startOfDay(zonedDate);

				const startOfZonedDateUTC = zonedTimeToUtc(startOfZonedDate, tz);

				const todayStartTimestamp = startOfZonedDateUTC.getTime();

				// import tariff
				let importTariffData = getImportTariffData(importTariff, importTariff?.hhRatesPerKwh);
				
				// export tariff
				let exportTariffData = getImportTariffData(exportTariff, exportTariff?.hhRatesPerKwh);

				let labels;
				const dataSets = [];

				if (importTariffData) {
					labels = importTariffData.map((ele) => getZonedTimestamp(ele.x * 3600000 + todayStartTimestamp));
					const data = importTariffData.map((ele) => ele.y);
					dataSets.push({
						label: tariffImport.type,
						data,
						borderWidth: 1,
						backgroundColor: tariffImport.backgroundColor,
						borderColor: tariffImport.borderColor,
						pointRadius: 0,
						pointHoverRadius: 2,
					});
				}

				if (exportTariffData) {
					if (!labels) {
						labels = exportTariffData.map((ele) => ele.x * 3600000 + todayStartTimestamp);
					}
					const data = exportTariffData.map((ele) => ele.y);
					dataSets.push({
						label: tariffExport.type,
						data,
						borderWidth: 1,
						backgroundColor: tariffExport.backgroundColor,
						borderColor: tariffExport.borderColor,
						pointRadius: 0,
						pointHoverRadius: 2,
					});
				}

				const start = labels[0] / 1000;
				const end = labels[labels.length - 1] / 1000;
				const annotation = end - start <= 90000 ? CONTRACT_ANNOTATION(end, timezone) : {};
				const timeFormat = getTimeFormat('half-hour');
				// tick values

				handleSetChart(labels, dataSets, timeFormat, annotation, timezone);
			}
		} catch (err) {
			console.log(err);
		}
	}, [importTariff, exportTariff, timezone]);

	useEffect(() => {
		getGraphData();
	}, [getGraphData]);

	return (
		<div>
			<div style={{ height: 300 }}>
				<Line id={customerChartTitles.tariff[0]} data={chart.data} options={chart.options} />
			</div>
		</div>
	);
}

export default TariffLineChart;
