/* Copyright Levelise Ltd 2019-2021 */
import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { fromPv, fromBattery, fromGrid, toLoad, heating, toBattery, toGrid } from '../../utils/chart';
import { TIME_FRAMES } from '../../utils/constants';

const SummaryChart = ({ facility, records, timeFrame, dates }) => {
    const timeFrames = [
        TIME_FRAMES.one_week,
        TIME_FRAMES.twenty_one_days,
        TIME_FRAMES.three_months,
        TIME_FRAMES.twelve_months,
        TIME_FRAMES.thirty_six_months,
        TIME_FRAMES.all,
    ];
    const conversionFactor = {
        [TIME_FRAMES.twenty_four_hours]: 60 * 1000,
        [TIME_FRAMES.six_hours]: 60 * 1000,
        [TIME_FRAMES.one_hour]: 60 * 1000,
        [TIME_FRAMES.fifteen_minutes]: 60 * 60 * 1000
    };
    const [chart, setChart] = useState({
        consumptionChart: {
            data: {
                labels: [fromPv.type, fromBattery.type, fromGrid.type],
                datasets: [{
                    backgroundColor: [
                        fromPv.borderColor,
                        fromBattery.borderColor,
                        fromGrid.borderColor],
                    hoverBackgroundColor: [
                        fromPv.backgroundColor,
                        fromBattery.backgroundColor,
                        fromGrid.backgroundColor],
                    data: []
                }]
            },
            options: {
                responsive: true,
                aspectRatio: 1.5,
                maintainAspectRatio: true,
                plugins: {
                    legend: {
                        display: true,
                        position: 'right',
                        align: 'end',
                        labels: {
                            font: { size: 10 }, 
                            color: 'black',
                            usePointStyle: true,
                            boxWidth: 7,
                            boxHeight: 7,
                            padding: 5,
                        }
                    },
                    title: {
                        display: true,
                        text: 'Total Consumption',
                        color: 'black'
                    },
                    lineTracker: false
                }
            }
        },
        productionChart: {
            data: {
                labels: [toLoad.type, toBattery.type, toGrid.type],
                datasets: [{
                    backgroundColor: [
                        toLoad.borderColor,
                        toBattery.borderColor,
                        toGrid.borderColor
                    ],
                    hoverBackgroundColor: [
                        toLoad.backgroundColor,
                        toBattery.backgroundColor,
                        toGrid.backgroundColor
                    ],
                    data: []
                }]
            },
            options: {
                responsive: true,
                aspectRatio: 1.5,
                maintainAspectRatio: true,
                plugins: {
                    legend: {
                        display: true,
                        position: 'right',
                        align: 'end',
                        labels: {
                            font: { size: 10 }, 
                            color: 'black',
                            usePointStyle: true,
                            boxWidth: 7,
                            boxHeight: 7,
                            padding: 8,
                        }
                    },
                    title: {
                        display: true,
                        text: `Total Production`,
                        color: 'black'
                    },
                    lineTracker: false
                }
            }
        }
    })

    const handleIsKwh = () => {
        if (timeFrames.includes(timeFrame)) {
            return true;
        } else if (timeFrame === TIME_FRAMES.select) {
            if (dates.start === dates.end) {
                if (records.length <= 48) return true;
                else return false;
            } else {
                return true;
            }
        }
    }

    const populateChartData = () => {
        const factor = handleIsKwh(timeFrame) ? 1 : timeFrame === TIME_FRAMES.select
            ? conversionFactor[TIME_FRAMES.twenty_four_hours]
            : conversionFactor[timeFrame];
        const loadKey = handleIsKwh(timeFrame) ? 'loadKwh' : 'loadW';
        const pvKey = handleIsKwh(timeFrame) ? 'pvKwh' : 'pvW';
        const loadFromPvKey = handleIsKwh(timeFrame) ? 'loadFromPvKwh' : 'loadFromPvW';
        const loadFromBatteryKey = handleIsKwh(timeFrame) ? 'loadFromBatteryKwh' : 'loadFromBatteryW';
        const hwHeatingKey = handleIsKwh(timeFrame) ? 'hwHeatingKwh' : 'hwHeatingW';
        const hwFromPvKey = handleIsKwh(timeFrame) ? 'hwFromPvKwh' : 'hwFromPvW';
        const hwFromBatteryKey = handleIsKwh(timeFrame) ? 'hwFromBatteryKwh' : 'hwFromBatteryKwh';
        const batteryChargingKey = handleIsKwh(timeFrame) ? 'batteryChargingKwh' : 'batteryChargingW';
        const batteryDischargingKey = handleIsKwh(timeFrame) ? 'batteryDischargingKwh' : 'batteryDischargingW';
        const batteryFromPvKey = handleIsKwh(timeFrame) ? 'batteryFromPvKwh' : 'batteryFromPvW';
        const gridIncomingKey = handleIsKwh(timeFrame) ? 'gridIncomingKwh' : 'gridIncomingW';
        const gridOutgoingKey = handleIsKwh(timeFrame) ? 'gridOutgoingKwh' : 'gridOutgoingW';
        const kwargs = {
            records, timeFrame, factor, loadKey, pvKey, loadFromPvKey, loadFromBatteryKey, hwHeatingKey,
            hwFromPvKey, hwFromBatteryKey, batteryChargingKey, batteryDischargingKey, batteryFromPvKey,
            gridIncomingKey, gridOutgoingKey
        };

        if (kwargs.timeFrame === TIME_FRAMES.fifteen_minutes) {
            const { totalConsumption, totalProduction, consumptionData, productionData } = calculateSummaryDataBySecond(kwargs);
            callSetChart(totalConsumption, totalProduction, consumptionData, productionData, factor);
        } else {
            const { totalConsumption, totalProduction, consumptionData, productionData } = calculateSummaryData(kwargs);
            callSetChart(totalConsumption, totalProduction, consumptionData, productionData, factor);
        }
    }

    const parseKwh = val => {
        const result = parseFloat(val)
        return isNaN(result) || result === 0 ? null : `${result} kWh`
    }

    const callSetChart = (totalConsumption, totalProduction, consumptionData, productionData, factor) => {
        const hasHw = facility.hasOwnProperty('hotWaterTank');
        const hasBattery = facility.hasOwnProperty('batterySystem')
        let productionLabels = [...chart.productionChart.data.labels];
        let consumptionLabels = [...chart.consumptionChart.data.labels];
        let productionColors = { ...chart.productionChart.data.datasets[0] };
        let consumptionColors = { ...chart.consumptionChart.data.datasets[0] };
        if (hasHw) {
            if (hasBattery) {
                productionLabels = [toLoad.type, heating.type, toBattery.type, toGrid.type];
                productionColors = {
                    backgroundColor: [toLoad.borderColor, heating.borderColor, toBattery.borderColor, toGrid.borderColor],
                    hoverBackgroundColor: [toLoad.backgroundColor, heating.backgroundColor, toBattery.backgroundColor,
                    toGrid.backgroundColor]
                };
            } else {
                productionLabels = [toLoad.type, heating.type, toGrid.type];
                productionColors = {
                    backgroundColor: [toLoad.borderColor, heating.borderColor, toGrid.borderColor],
                    hoverBackgroundColor: [toLoad.backgroundColor, heating.backgroundColor, toGrid.backgroundColor]
                };
                productionData.splice(2, 1)  // remove to battery
            }
        } else {
            productionData.splice(1, 1);  // remove HW value spot
        }

        if (!hasBattery) {
            consumptionLabels = [fromPv.type, fromGrid.type]
            consumptionColors = {
                backgroundColor: [fromPv.borderColor, fromGrid.borderColor],
                hoverBackgroundColor: [fromPv.backgroundColor, fromGrid.backgroundColor],
            }
            consumptionData.splice(1, 1)  // remove from battery
        }

        setChart({
            consumptionChart: {
                data: {
                    labels: consumptionLabels,
                    datasets: [{
                        ...consumptionColors,
                        data: consumptionData.map(d => d.toFixed(2))
                    }]
                },
                options: {
                    ...chart.consumptionChart.options,
                    plugins: {
                        ...chart.consumptionChart.options.plugins,
                        title: {
                            ...chart.consumptionChart.options.plugins.title,
                            display: true,
                            text: `Total Consumption (${(totalConsumption / factor).toFixed(2)} kWh)`
                        },
                        tooltip: { callbacks: { label: callbackLabel } },

                        datalabels: {
                            display: true,
                            formatter: (val, ctx) => parseKwh(val),
                            backgroundColor: (context) => context.dataset.backgroundColor,
                            color: 'white',
                            borderRadius: 3,
                            anchor: 'center',
                            font: { size: 10 },
                            padding: { top: 2, right: 2, bottom: 1, left: 2 }
                        },

                    }
                },
            },
            productionChart: {
                data: {
                    labels: productionLabels,
                    datasets: [{
                        ...productionColors,
                        data: productionData.map(d => d.toFixed(2))
                    }]
                },
                options: {
                    ...chart.productionChart.options,
                    plugins: {
                        ...chart.productionChart.options.plugins,
                        title: {
                            ...chart.productionChart.options.plugins.title,
                            display: true,
                            text: `Total Production (${(totalProduction / factor).toFixed(2)} kWh)`
                        },
                        tooltip: { callbacks: { label: callbackLabel } },
                        datalabels: {
                            display: true,
                            formatter: (val, ctx) => parseKwh(val),
                            backgroundColor: (context) => context.dataset.backgroundColor,
                            color: 'white',
                            borderRadius: 3,
                            anchor: 'center',
                            font: { size: 10 },
                            padding: { top: 2, right: 2, bottom: 1, left: 2 }
                        },
                    }
                }
            }
        })
    }

    const callbackLabel = (context) => {
        const label = context.label || '';
        const currentValue = context.parsed;
        return `${label}: ${currentValue}kWh`;
    }

    const calculateSummaryDataBySecond = (kwargs) => {
        let totalConsumption = 0, totalProduction = 0;
        const consumptionData = [], productionData = [];
        kwargs.records.forEach(report => {
            const fromPv = report[kwargs.pvKey];
            const toLoad = report[kwargs.loadKey];
            const toHw = report[kwargs.hwHeatingKey];
            const toBattery = report[kwargs.batteryChargingKey];
            const fromBattery = report[kwargs.batteryDischargingKey];
            const toGrid = report[kwargs.gridOutgoingKey];
            const loadFromPv = Math.min(toLoad, fromPv);
            const loadFromBattery = Math.max(0, Math.min(toLoad - fromPv, fromBattery));
            const loadFromGrid = toLoad - (loadFromPv + loadFromBattery);
            const hwFromPv = Math.max(0, Math.min(toHw, fromPv - loadFromPv));
            const hwFromBattery = Math.max(0, Math.min(toHw - hwFromPv, fromBattery - loadFromBattery));
            const hwFromGrid = toHw - ((hwFromPv || 0) + (hwFromBattery || 0));
            const batteryFromPv = Math.max(0, Math.min(toBattery, fromPv - (loadFromPv + (hwFromPv || 0))));
            const gridFromPV = Math.max(0, Math.min(toGrid, fromPv - (loadFromPv + (hwFromPv || 0) + batteryFromPv)));

            totalConsumption += (toLoad + (toHw || 0));
            totalProduction += fromPv;
            consumptionData[0] = (consumptionData[0] || 0) + (loadFromPv + (hwFromPv || 0)) / kwargs.factor;
            consumptionData[1] = (consumptionData[1] || 0) + (loadFromBattery + (hwFromBattery || 0)) / kwargs.factor;
            consumptionData[2] = (consumptionData[2] || 0) + (loadFromGrid + (hwFromGrid || 0)) / kwargs.factor;
            productionData[0] = (productionData[0] || 0) + loadFromPv / kwargs.factor;
            productionData[1] = (productionData[1] || 0) + hwFromPv / kwargs.factor;
            productionData[2] = (productionData[2] || 0) + batteryFromPv / kwargs.factor;
            productionData[3] = (productionData[3] || 0) + gridFromPV / kwargs.factor;
        });
        return { totalConsumption, totalProduction, consumptionData, productionData }
    }

    const calculateSummaryData = (kwargs) => {
        let totalConsumption = 0, totalProduction = 0;
        const consumptionData = [], productionData = [];
        kwargs.records.forEach(report => {
            const fromPv = report[kwargs.pvKey];
            const toLoad = report[kwargs.loadKey];
            const loadFromPv = report[kwargs.loadFromPvKey];
            const loadFromBattery = report[kwargs.loadFromBatteryKey];
            const loadFromGrid = toLoad - loadFromPv - loadFromBattery;
            const toHw = report[kwargs.hwHeatingKey];
            const hwFromPv = report[kwargs.hwFromPvKey];
            const hwFromBattery = report[kwargs.hwFromBatteryKey];
            const hwFromGrid = toHw - (hwFromPv || 0) - (hwFromBattery || 0);
            const batteryFromPv = report[kwargs.batteryFromPvKey];
            const gridFromPV = fromPv - loadFromPv - (hwFromPv || 0) - batteryFromPv;

            totalConsumption += (toLoad + (toHw || 0));
            totalProduction += fromPv;
            consumptionData[0] = (consumptionData[0] || 0) + (loadFromPv + (hwFromPv || 0)) / kwargs.factor;
            consumptionData[1] = (consumptionData[1] || 0) + (loadFromBattery + (hwFromBattery || 0)) / kwargs.factor;
            consumptionData[2] = (consumptionData[2] || 0) + (loadFromGrid + (hwFromGrid || 0)) / kwargs.factor;
            productionData[0] = (productionData[0] || 0) + loadFromPv / kwargs.factor;
            productionData[1] = (productionData[1] || 0) + hwFromPv / kwargs.factor;
            productionData[2] = (productionData[2] || 0) + batteryFromPv / kwargs.factor;
            productionData[3] = (productionData[3] || 0) + gridFromPV / kwargs.factor;
        });
        return { totalConsumption, totalProduction, consumptionData, productionData }
    }

    useEffect(() => {
        populateChartData();
    }, [records, timeFrame])

    return (
        <div className="summary-chart">
            <Pie
                id='facilityConsumption'
                width={document.width <= 1440 ? 140 : 140}
                data={chart.consumptionChart.data}
                options={chart.consumptionChart.options}
                plugins={[ChartDataLabels]}
            />
            <Pie
                id='facilityProduction'
                width={document.width <= 1440 ? 140 : 140}
                data={chart.productionChart.data}
                options={chart.productionChart.options}
                plugins={[ChartDataLabels]}
            />
        </div>
    )
}

export default SummaryChart;