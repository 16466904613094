/* Copyright Levelise Ltd 2019-2024 */
import config from '../config';
import Cookies from 'universal-cookie';
import IdleService from './idle-service';
import UserService from './user-service';

let _timeoutId;
const _ms_before_expiry = 10 * 1000;
const cookies = new Cookies();

const TokenService = {
    saveState(state) {
        sessionStorage.setItem(config.state, state);
    },

    getState() {
        return sessionStorage.getItem(config.state)
    },

    saveCodeVerifier(codeVerifier) {
        sessionStorage.setItem(config.codeVerifier, codeVerifier);
    },

    getCodeVerifier() {
        return sessionStorage.getItem(config.codeVerifier)
    },

    saveAuthToken(token) {
        cookies.set(config.Auth_Token, token, { path: '/' });
    },

    getAuthToken() {
        return cookies.get(config.Auth_Token);
    },

    clearAuthToken() {
        cookies.remove(config.Auth_Token);
    },

    hasAuthToken() {
        return !!cookies.get(config.Auth_Token);
    },

    makeBasicAuthToken(userName, password) {
        return window.btoa(`${userName}:${password}`);
    },

    getDataFromAuthToken(type) {
        if (TokenService.hasAuthToken())
            return TokenService.getAuthToken()[type];
        else
            TokenService.logoutFromTokenError();
    },

    logoutFromTokenError() {
        window.location.href = '/';
        TokenService.clearAuthToken();
        UserService.removeUser();
        UserService.removeUserDetail();
        TokenService.clearCallbackBeforeExpiry();
        IdleService.unRegisterIdleResets();
    },

    queueCallbackBeforeExpiry(callback) {
        const ms_until_expiry = parseInt(TokenService.getDataFromAuthToken('expires_in')) * 1000;
        let ms_until_cb = ms_until_expiry - _ms_before_expiry;

        if (ms_until_cb < _ms_before_expiry) {
            ms_until_cb = 1000;
        }

        clearTimeout(_timeoutId);
        _timeoutId = setTimeout(callback, ms_until_cb);
    },

    clearCallbackBeforeExpiry() {
        clearTimeout(_timeoutId);
    },

    getTimeoutId() {
        return _timeoutId
    }
}

export default TokenService
